import moment from 'moment';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { IDealerTicket } from 'thunks/RTKQuery/dealer';
import i18n from 'translations';

export const columns: ColumnDescription<any, IDealerTicket>[] = [
  {
    dataField: 'ticketId',
    text: i18n.t('dealerManagement.tickets.dataColumns.ticketId'),
    sort: true,
  },
  {
    dataField: 'parentDealerId',
    text: i18n.t('dealerManagement.tickets.dataColumns.parentDealerId'),
    sort: true,
  },
  {
    dataField: 'dealerId',
    text: i18n.t('dealerManagement.tickets.dataColumns.dealerId'),
    sort: true,
  },

  {
    dataField: 'productText',
    text: i18n.t('dealerManagement.tickets.dataColumns.productText'),
    sort: true,
  },
  {
    dataField: 'isUpdateAvailable',
    text: i18n.t('dealerManagement.tickets.dataColumns.isUpdateAvailable'),
    sort: true,
  },

  {
    dataField: 'expiryDate',
    text: i18n.t('dealerManagement.tickets.dataColumns.expiryDate'),
    formatter: (cell: any, row: IDealerTicket) => {
      const d = moment(row.expiryDate);
      if (!d.isValid()) return row.expiryDate;
      return d.utc().format(i18n.t('formats.date.short'));
    },
    sort: true,
  },
  {
    dataField: 'minimumExpiryDate',
    text: i18n.t('dealerManagement.tickets.dataColumns.minimumExpiryDate'),
    formatter: (cell: any, row: IDealerTicket) => {
      const d = moment(row.minimumExpiryDate);
      if (!d.isValid()) return row.minimumExpiryDate;
      return d.utc().format(i18n.t('formats.date.short'));
    },
    sort: true,
  },
  {
    dataField: 'maintenanceExpiry',
    text: i18n.t('dealerManagement.tickets.dataColumns.maintenanceExpiry'),
    formatter: (cell: any, row: IDealerTicket) => {
      const d = moment(row.maintenanceExpiry);
      if (!d.isValid()) return row.maintenanceExpiry;
      return d.utc().format(i18n.t('formats.date.short'));
    },
    sort: true,
  },
];
