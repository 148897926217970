import React from 'react';
import { ExpandColumnRendererProps } from 'react-bootstrap-table-next';
import {
  IDealerTicket,
  useFetchDealerTicketActiveProductDetailMutation,
} from 'thunks/RTKQuery/dealer';
import { LicenseDetailComponent } from 'pages/Public/LicenseInfo/LicenseDetails/licenseDetailComponent';
import { Spinner2 } from 'components';
import { useTranslation } from 'react-i18next';
import { DropDownIcon, DropDownReverseIcon } from '../../../icons';

export const expandDTRow = () => {
  const { t } = useTranslation();

  const [
    fetchDealerTicketActiveProductDetail,
    { data, isLoading, error },
  ] = useFetchDealerTicketActiveProductDetailMutation();

  const licenseDetails = data?.licenseDetails ?? [];
  type ExpandPosition = 'left' | 'right';

  return {
    parentClassName: 'table-expanded-row',
    renderer: (row: IDealerTicket) => {
      return (
        <>
          {licenseDetails?.length > 0 && (
            <LicenseDetailComponent
              data={licenseDetails || []}
              nonExpandables={licenseDetails
                .map((item: any) =>
                  item.children.length === 0 ? item.code : ''
                )
                .filter((code: any) => !!code)}
            />
          )}
          <Spinner2 show={isLoading} />
          {licenseDetails?.length === 0 && !isLoading && !error && (
            <div className="text-center text-primary">
              {t('dealerManagement.tickets.noActiveTicketsFound', {
                ticketId: row.ticketId,
              })}
            </div>
          )}

          {error && !isLoading && (
            <div className="text-center text-danger">{`Error: ${error}`}</div>
          )}
        </>
      );
    },
    onExpand: async (row: IDealerTicket) => {
      setTimeout(async () => {
        await fetchDealerTicketActiveProductDetail({ ticketId: row.ticketId });
      }, 100);
    },
    showExpandColumn: true,
    expandColumnPosition: 'right' as ExpandPosition,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => <div />,
    expandColumnRenderer: ({
      expanded,
      expandable,
    }: ExpandColumnRendererProps) => {
      if (!expandable) {
        return <div />;
      }
      if (expanded) {
        return <DropDownReverseIcon />;
      }
      return <DropDownIcon />;
    },
    nonExpandable: [],
  };
};
