import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { columns } from './columns';
import { useAppSelector } from 'store/hooks';
import { expandColumns } from './expandColumns';

export interface ILicenseDetailComponentProps {
  data: any;
  nonExpandables: any;
}

export const LicenseDetailComponent: React.FC<ILicenseDetailComponentProps> = ({
  data = [],
  nonExpandables,
}: ILicenseDetailComponentProps) => {
  const { t } = useTranslation();
  const rowClasses = (row: any, rowIndex: any) =>
    !!row.enable ? 'license-content-table-row' : '';

  const expandRow = {
    renderer: (row: any) => (
      <BootstrapTable
        keyField="code"
        data={row.children}
        columns={expandColumns}
        wrapperClasses="table-license-content table-wrapper"
        rowClasses={rowClasses}
      />
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    nonExpandable: nonExpandables,
  };

  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col md={2}>
          <small className="ml-2 mb-2 fs-10 font-weight-bold">
            {data.length} {t('licenseContent.products')}
          </small>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col>
          <div className="div-border">
            <BootstrapTable
              keyField="code"
              data={data}
              columns={columns}
              wrapperClasses="table-license-content table-wrapper"
              rowClasses={rowClasses}
              expandRow={expandRow}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
