import React from 'react';
import { Col, Row } from 'react-bootstrap';
import i18n from 'translations';

export default function AggregationView({ overview }: any) {
  return (
    <Row className="mt-20 ml-12" style={{ backgroundColor: '#fa960021' }}>
      <Col className="ml-6">
        <strong>{i18n.t('hdUsageInfo.runningMachines')}: </strong>
        {overview?.isRunning}
      </Col>
      <Col className="ml-6">
        <strong>{i18n.t('hdUsageInfo.concurrentUsers')}: </strong>
        {overview?.concurrentlyUsedTickets}
      </Col>
    </Row>
  );
}
