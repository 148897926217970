import React, { useEffect, useState } from 'react';
import { DealerListViewContainer } from 'pages/Shared/Dealer/DealerListView/DealersListViewContainer';
import { useGerBranchDealerListQuery } from 'thunks/RTKQuery/dealer';
import { Spinner2 } from 'components';
import { IDealerItem, IDealerObject } from 'pages/Shared/Dealer/Models/dealers';
import { IDealerInfo } from 'slices/auth';

interface IRootDealerListViewContainer {
  setErrorString: (err: string) => void;
  setSelectedDealer: (dealer: IDealerItem) => void;
  selectedDealer?: IDealerItem;
  dealerListData: IDealerItem[];
  setDealerListData: (data: IDealerItem[]) => void;
  rootDealer?: IDealerInfo;
  setShowList: (show: boolean) => void;
}
// create DealerListView container for dealer management
export const RootDealerListViewContainer: React.FC<IRootDealerListViewContainer> = ({
  setErrorString,
  setSelectedDealer,
  selectedDealer,
  dealerListData,
  setDealerListData,
  rootDealer,
  setShowList,
}: IRootDealerListViewContainer) => {
  const [selectedId, setSelectedId] = useState<number>(0);

  useEffect(() => {
    if (rootDealer?.id) {
      setSelectedId(rootDealer.id);
    }
  }, [rootDealer]);

  // load dealer list data
  const {
    data: dealerData = [],
    error: dealerListError,
    isLoading: dealerListLoading,
    // refetch: refetchDealerList,
  } = useGerBranchDealerListQuery(
    selectedId,
    selectedId === 0 ? { skip: true } : {}
  );

  useEffect(() => {
    if (!dealerData) return;
    setDealerListData(dealerData);
    const list = dealerData as IDealerObject[];
    if (list && list.length === 1 && selectedDealer === undefined) {
      const selectedObj = list[0];
      setSelectedDealer(selectedObj);
      setShowList(false);
    } else {
      setShowList(true);
    }
  }, [dealerData]);

  useEffect(() => {
    if (dealerListError && 'message' in dealerListError) {
      setErrorString(`${dealerListError.message}`);
    }
  }, [dealerListError]);

  return (
    <>
      <Spinner2 show={dealerListLoading} />
      <DealerListViewContainer
        setSelectedDealer={setSelectedDealer}
        dealerData={dealerListData}
        selectedDealer={selectedDealer}
      />
    </>
  );
};

RootDealerListViewContainer.defaultProps = {
  selectedDealer: undefined,
  rootDealer: undefined,
};
