import React, { useEffect, useState } from 'react';

import { authSlice, confirmationSlice, toastSlice } from 'slices';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { isFetchBaseQueryError } from 'utils/helpers';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useInviteAsDealerUserMutation } from 'thunks/RTKQuery/dealer';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import i18n from 'translations';
import { CONFIRMATION_ACTIONS } from 'slices/dealer-details';
import { Spinner2 } from 'components/Spinner2';
import { useBranchDealerSelf } from 'hooks/role';
import { ROOT_ROUTE } from 'constants/routes';
import { InviteUsers } from './InviteUsers';
import { Confirmation } from '../Confirmation';

interface IDealerInviteUserContainer {
  setErrorString: (err: string) => void;
  selectedDealer?: IDealerItem;
  setSelectedDealer: (dealer: IDealerItem) => void;
  appendListViewData: (data: IDealerItem) => void;
}

export const InviteUsersContainer = ({
  setErrorString,
  selectedDealer,
  setSelectedDealer,
  appendListViewData,
}: IDealerInviteUserContainer) => {
  const dispatch = useAppDispatch();
  const { showInviteUserModel } = useAppSelector((state) => state.auth);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [emailUpdate, setEmailUpdate] = useState('');
  const self = useBranchDealerSelf(selectedDealer?.userId);

  const [
    updateDealer,
    {
      isLoading: dealerUpdateLoading,
      error: dealerUpdateError,
      status: dealerUpdateStatus,
    },
  ] = useInviteAsDealerUserMutation();

  useEffect(() => {
    if (dealerUpdateError && isFetchBaseQueryError(dealerUpdateError)) {
      const msg = dealerUpdateError as any;
      setErrorString(`${msg.data.message.errors}`);
    }
  }, [dealerUpdateError]);

  useEffect(() => {
    if (dealerUpdateStatus === QueryStatus.fulfilled && !self) {
      dispatch(
        toastSlice.actions.show({
          title: 'Invite Send',
          message: 'Invitation has been sent successfully',
        })
      );
      dispatch(authSlice.actions.closeInviteUserModel());
    }
  }, [dealerUpdateStatus]);

  const onClose = () => {
    dispatch(authSlice.actions.closeInviteUserModel());
  };

  const handleLogout = () => {
    const payload = { redirectTo: ROOT_ROUTE };
    dispatch(authSlice.actions.logout());
    dispatch(authSlice.actions.gotoHome(payload));
  };

  const submitData = async (e: string) => {
    if (!selectedDealer) return;
    const props = 'id, name, dealerId, email, userId';
    const result: any = await updateDealer({
      invitedDealerId: selectedDealer?.id,
      email: e,
      props,
    });

    if (!result?.error) {
      const resultData = result?.data as IDealerItem;
      setSelectedDealer(resultData);
      appendListViewData(resultData);
      if (self) {
        handleLogout();
      }
    }
  };

  const handleSubmit = async (e: string) => {
    if (!selectedDealer) return;
    if (selectedDealer && selectedDealer.userId === null) {
      submitData(e);
    } else {
      setEmailUpdate(e);
      dispatch(
        confirmationSlice.actions.show({
          title: i18n.t('dealerManagement.updateConfirm.title'),
          message: i18n.t(
            !self
              ? 'dealerManagement.updateConfirm.message'
              : 'dealerManagement.updateConfirm.messageSelf'
          ),
          buttonOk: i18n.t('dealerManagement.updateConfirm.buttonOk'),
          buttonCancel: i18n.t('dealerManagement.updateConfirm.buttonCancel'),
          actionKey: CONFIRMATION_ACTIONS.INVITE,
        })
      );
      dispatch(authSlice.actions.closeInviteUserModel());
      setConfirmDialog(true);
    }
  };

  const handleConfirmationSubmit = async (actionKey?: string) => {
    setIsProcessing(true);
    if (emailUpdate && actionKey === CONFIRMATION_ACTIONS.INVITE) {
      dispatch(confirmationSlice.actions.close());
      await submitData(emailUpdate);
    }
    setConfirmDialog(false);
    setIsProcessing(false);
  };

  return (
    <>
      <Spinner2 show={isProcessing} />
      <InviteUsers
        show={showInviteUserModel}
        selectedDealer={selectedDealer}
        userSaveLoading={dealerUpdateLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />{' '}
      {(showInviteUserModel || confirmDialog) && (
        <Confirmation key="invite-users" onConfirm={handleConfirmationSubmit} />
      )}
    </>
  );
};

InviteUsersContainer.defaultProps = {
  selectedDealer: undefined,
};
