export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Niemiecki',
    en: 'Angielski',
    it: 'Włoski',
    fr: 'Francuski',
    sp: 'Hiszpański',
    pl: 'Polski',
  },
  search: 'Szukaj',
  goBack: 'Wstecz',
  signIn: {
    title: 'Zaloguj się',
    dontHaveAccount: 'Nie masz konta?',
  },
  signUp: {
    title: 'Zarejestruj się',
    info:
      'Poprzez rejestrację zgadzasz się na nasze <0>{{privacy}}</0> i <1>{{terms}}</1>.',
    promotions:
      'Nie chcę otrzymywać aktualizacji i promocji drogą elektroniczną.',
    alreadyHaveAccount: 'Masz już konto?',
    serviceterms: 'Zgadzam się na <0>{{service}}</0>.',
    dataprocessing: 'Zgadzam się na <0>{{agreement}}</0>.',
  },
  signOut: {
    title: 'Wyloguj się',
  },
  email: {
    label: 'Adres email',
    placeholder: 'Adres email',
    taken: 'Email jest już zajęty',
  },
  password: {
    label: 'Hasło',
    placeholder: 'Wprowadź hasło',
    mismatch: 'Hasła muszą się zgadzać.',
  },
  passwordConfirmation: {
    label: 'Potwierdź hasło',
    placeholder: 'Wpisz hasło potwierdzające',
  },
  firstName: {
    label: 'Imię',
    placeholder: 'Wprowadź imię',
  },
  lastName: {
    label: 'Nazwisko',
    placeholder: 'Wprowadź nazwisko',
  },
  activationKey: {
    label: 'Klucz aktywacyjny',
    placeholder: 'Wprowadź klucz aktywacyjny',
  },
  companyName: {
    label: 'Nazwa firmy',
    placeholder: 'Wprowadź nazwę firmy',
  },
  contactPerson: {
    label: 'Osoba kontaktowa',
    placeholder: 'Wprowadź nazwisko osoby kontaktowej',
  },
  country: {
    label: 'Kraj',
  },
  address: {
    label: 'Adres',
    placeholder: 'Dodaj adres',
  },
  city: {
    label: 'Miasto',
    placeholder: 'Dodaj miasto',
  },
  zip: {
    label: 'Kod pocztowy',
    placeholder: 'Wprowadź kod pocztowy',
  },
  web: {
    label: 'Strona internetowa',
    placeholder: 'Wprowadź stronę internetową',
  },
  uid: {
    label: 'NIP',
    placeholder: 'Wprowadź NIP',
  },
  vatId: {
    label: 'Numer VAT',
    placeholder: 'VAT (e.g: E123456789)',
  },
  state: {
    label: 'Województwo',
    placeholder: 'Wprowadź województwo',
  },
  street: {
    label: 'Ulica',
    placeholder: 'Wpisz ulicę',
  },
  phone: {
    label: 'Telefon',
    placeholder: 'Wprowadź telefon',
  },
  forgot: {
    title: 'Nie pamiętam hasła',
    info:
      'Nie martw się! Wystarczy podać swój adres e-mail, a my wyślemy Ci link do zresetowania hasła.',
    button: 'Wyślij zresetowane hasło',
    password: 'Nie pamiętasz hasła?',
  },
  header: {
    profile: 'Profil',
    settings: 'Ustawienia',
    companyInfo: 'Informacje o firmie',
    selectMill: 'Wybierz obróbkę',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'O nas',
        content:
          'Follow-Me! Technology Group opracowuje i dystrybuuje hyperDENT®. Systemy oprogramowania obejmujące cały proces produkcyjny za pomocą jednego oprogramowania CAM. Siedziba FOLLOW-ME! Siedziba Technology GmbH znajduje się w Monachium, a jej dyrektor generalny, dr Ferdinand Schwenkert i dyrektor ds. technicznych Günter Linseis, stale wprowadzają innowacje w zakresie nowych, inteligentnych rozwiązań w całym naszym asortymencie produktów, zachowując perspektywiczną perspektywę. <br> <br> Nasze oddziały spółki FOLLOW-ME! Technology Group ma swoje siedziby na całym świecie, m.in. we Włoszech, Chinach, Japonii, Korei, Singapurze i USA.',
        ceoMessage:
          '“Naszym zadaniem jest rozwiązywanie problemów w zakresie oprogramowania dentystycznego poprzez optymalizację procesów produkcji digitalizacją i optymalizacją.”',
      },
      contact: {
        title: 'Kontakt',
        content:
          '<strong>FOLLOW-ME! TECHNOLOGY GmbH, Regerstrasse 27, 81541 Munich, Germany. <br> Tel.: +49 89 452 170 60 . <br> E-Mail: info@follow-me-tech.com</strong>',
      },
      desktopApp: {
        title: 'Aplikacja komputerowa',
        content:
          'Wyższa produktywność, większa elastyczność, więcej miejsca do przechowywania i sprawniejsze procesy.',
      },
      privacyPolicy: {
        title: 'Polityka prywatności',
        content:
          'hyperDENT Connect to Twoja platforma do udostępniania cyfrowych plików stomatologicznych. Bezproblemowe udostępnianie dla klientów centrum frezowania, raportowanie statusu w czasie rzeczywistym i łatwa komunikacja łączy centra frezowania z ich klientami.',
      },
      termsOfService: {
        title: 'Warunki Usługi',
        content:
          '<strong> Obecnie pracujemy nad naszymi nowymi warunkami usługi. Sprawdź ponownie pózniej aby zobaczyć nowe warunki usługi użytkowania hyperDENT Connect.</strong>',
      },
      dataProcessing: {
        title: 'Umowa o przetwarzaniu danych',
        content:
          '<strong> Obecnie pracujemy nad naszymi nowymi warunkami umowy o przetwarzanie danych, sprawdź ponownie pózniej aby zobaczyć nowe warunki przetwarzania dla użytkowania hyperDENT Connect</strong>.',
      },
    },
  },
  home: {
    home: 'Strona główna',
    subtitle: 'hyperDENT Connect',
    title: 'Połącz się z ekspertami',
    content: 'Udostępnianie twoich prac restoracyjnych łatwą drogą.',
    reasons: {
      title: 'Czemu korzystać z hyperDent Connect?',
      reason1: {
        title: 'Elektroniczna stomatologia zmienia swoje wymagania. ',
        content:
          'Większa produktywność, większa elastyczność, więcej miejsca na dane i łatwiejszy proces',
      },
      reason2: {
        title: 'Edytuj serwis chumury',
        content: 'Nagłówek instruktażowy: Tekst tutajs',
      },
      reason3: {
        title: 'Łatwy dostęp do chmury ',
        content:
          'hyperDENT Connect umożliwia dostęp do plików klientów i korzystanie z aplikacji z dowolnego urządzenia, które ma dostęp do Internetu.',
      },
    },
    policiesupdate: {
      title: 'Aktualizacje zasad',
      content:
        'Prosimy o wyrażenie zgody na nasze zaktualizowane warunki świadczenia usług i umowę o przetwarzaniu danych.',
    },
  },
  welcome: {
    title: 'Witamy w hyperDENT Connect',
    content:
      'hyperDENT Connect jest platformą do udostępniania plików dentystycznych. Bezproblemowe udostępnianie frezarek, raporty w czasie rzeczywistym i łatwa komunikacja łącząca centra frezarkowe z ich klientami. ',
    landingPage: {
      title: '1. Utwórz stronę docelową',
      content: '',
      button: 'Ustawienia strony docelowej',
    },
    inviteClients: {
      title: '2. Zaproś klientów',
      content:
        'Dodaj laboratoria lub kliniki dentystyczne do swoich kont klientów, zapraszając ich za pomocą wstępnie zdefiniowanego linku z zaproszeniem. ',
      button: 'Zaproś klientów',
    },
  },
  labWelcome: {
    title: 'Tytuł instruktażowy',
    content: 'Nagłówek instruktażowy: Napisz tutaj',
    serviceApp: {
      title: 'Aplikacja Serwisowa',
      content: '',
      button: 'Pobierz aplikację',
    },
    dashboard: {
      title: 'Strona główna',
      content: '',
      button: 'Przejdź do panelu',
    },
  },
  orders: {
    title: 'Zamówienia',
    deleted: 'Usunięto',
    deletedFilesInfo:
      'Pliki tego projektu zostały usunięte, ponieważ limit przechowywania plików został przekroczony.',
    noSearch: 'Brak wyników wyszukiwania',
    noOrders: 'Brak utworzonych zamówień',
    winApp: 'Zamówienia są tworzone z aplikacji windows',
    downloadApp: 'Pobierz aplikację',
    columns: {
      orderId: 'Id zamówienia',
      name: 'Nazwa zamówienia',
      date: 'Data',
      messages: 'Wiadomości',
      components: 'Elementy',
      client: 'Klient',
      responsible: 'Odpowiedzialny za projekt',
      download: 'Pobierz',
      report: 'Zgłoś',
    },
    component: {
      columns: {
        name: 'Element',
        status: 'Status',
        messages: 'Wiadomości',
        files: 'Pliki',
        comment: 'Komentarz',
      },
    },
    messages: 'wiadomości',
    message: 'wiadomość',
    newMessage: 'nowy',
    select: {
      any: {
        client: 'Dowolny klient',
        responsible: 'Dowolny użytkownik',
      },
    },
  },
  project: {
    download: {
      flat: 'Płaski',
      hierarchical: 'Hierarchiczny',
    },
  },
  component: {
    orders: 'Zamówienia',
    component: 'Element',
    status: {
      label: 'Status elementu',
      any: 'Dowolny status',
      open: 'Otwórz',
      closed: 'Zamknięte',
      downloaded: 'Pobrane',
    },
    orderId: 'ID zamówienia',
    project: 'Projekt',
    preview: 'Podgląd elementu',
    documents: 'Dokumenty dotyczące elementu',
    downloadAll: 'Pobierz wszystko',
    downloads: 'Dokumenty do pobrania',
    uploadPdf: 'Prześlij Pdf',
    noFiles: 'Brak plików',
    messages: {
      title: 'Wiadomości',
      message: {
        placeholder: 'Napisz wiadomość',
      },
      button: 'Wyślij wiadomość',
    },
    upload: {
      title: 'Dodaj dokument',
      name: {
        label: 'Nazwa dokumentu',
        placeholder: 'Wprowadź nazwę dokumentu',
      },
      button: 'Prześlij',
    },
    download: {
      deleteConfirm: {
        title: 'Usuń dokument',
        message: 'Czy na pewno chcesz usunąć ten dokument?',
        buttonOk: 'Usuń',
        buttonCancel: 'Anuluj',
      },
      deleteToast: {
        title: 'Usuń dokument',
        message: 'Dokument został usunięty pomyślnie.',
      },
      uploadToast: {
        title: 'Prześlij dokument',
        message: 'Dokument został przesłany pomyślnie.',
      },
    },
    updateToast: {
      title: 'Zaktualizuj element',
      message: 'Element został zaktualizowany pomyślnie.',
    },
    messageCreateToast: {
      title: 'Wyślij wiadomość',
      message: 'Wiadomość została wysłana pomyślnie.',
    },
    updateOrderToast: {
      title: 'Zaktualizuj zamówienie',
      message: 'Zamówienie zostało zaktualizowane pomyślnie',
    },
  },
  fileUpload: {
    label: 'Przeciągnij i upuść lub kliknij, aby przesłać',
  },
  clients: {
    title: 'Klienci',
    addClient: 'Dodaj klienta',
    status: {
      label: 'Status',
      any: 'Dowolny status',
      active: 'Aktywny',
      inactive: 'Nieaktywny',
    },
    columns: {
      company: 'Firma',
      email: 'Email',
      lab: 'Nazwa laboratorium',
      contactPerson: 'Osoba kontaktowa',
      status: 'Status',
    },
    actions: {
      sendInvite: 'Wyślij zaproszenie',
      delete: 'Usuń',
      blocked: 'Blokuj',
      unBlock: 'Odblokuj',
    },
    deleteConfirm: {
      title: 'Usuń Klienta',
      message: 'Czy na pewno chcesz usunąć klienta?',
      buttonOk: 'Usuń',
      buttonCancel: 'Anuluj',
    },
    blockedConfirm: {
      title: 'Zablokuj klienta',
      message: 'Czy na pewno chcesz zablokować klienta?',
      buttonOk: 'Blokuj',
      buttonCancel: 'Anuluj',
    },
    unblockedConfirm: {
      title: 'Odblokuj klienta',
      message: 'Czy na pewno chcesz odblokować klienta?',
      buttonOk: 'Odblokuj',
      buttonCancel: 'Anuluj',
    },
    deleteToast: {
      title: 'Usuń klienta',
      message: 'Klient usunięty pomyślnie',
    },
    blockToast: {
      title: 'Zablokuj klienta',
      message: 'Klient został pomyślnie zablokowany.',
    },
    unblockToast: {
      title: 'Odblokuj klienta',
      message: 'Klient został odblokowany pomyślnie.',
    },
    blockNotAllowedToast: {
      title: 'Odblokuj klienta',
      message:
        'Ten klient jest zablokowany przez system. Skontaktuj się z administratorem.',
    },
    client: {
      title: 'Dodaj klienta',
      button: 'Dodaj klienta',
    },
    activation: {
      title: 'Instrukcje aktywacji',
      info:
        'Klient został dodany pomyślnie. Możesz skopiować i użyć tego szablonu wiadomości do kontaktu z nowo dodanym klientem.',
      message: {
        label: 'Wiadomość z zaproszeniem',
        placeholder: 'Wprowadź wiadomość z zaproszeniem',
      },
      copy: 'Kopiuj wiadomość',
      send: 'Wyślij e-mail',
      confirm: {
        title: 'Poczta z instrukcjami',
        message: 'Mail z instrukcją został pomyślnie wysłany do klienta',
        buttonOk: 'Zamknij',
      },
      errorConfirm: {
        title: 'Błąd aktywacji',
        buttonOk: 'Ok',
      },
    },
    millingcenterClientAdded: {
      title: 'Dodano klienta do Millingcenter',
      message:
        "Klient centrum frezowania '{{millClient}}' został dodany do przestrzeni roboczej.",
      buttonOk: 'Świetnie',
    },
  },
  messages: {
    title: 'Wiadomości',
    status: {
      any: 'Jakiekolwiek komunikaty',
      read: 'Czytaj',
      unread: 'Nieprzeczytane',
    },
    noMessages: 'Nie masz wiadomości',
  },
  settings: {
    title: 'Ustawienia',
    tabs: {
      landingPage: 'Strona docelowa',
      companyDetails: 'Dane firmy',
      users: 'Użytkownicy',
      licenseKeys: 'Klucze licencji',
    },
    company: {
      logo: {
        title: 'Logo firmy',
        label: 'Logo',
      },
      info: {
        title: 'Informacje o firmie',
      },
      button: 'Aktualizacja',
      message: {
        update: 'Prosimy o aktualizację danych firmy.',
      },
      error: {
        title: 'Wystąpił błąd',
        message:
          'Wystąpił błąd podczas zapisywania szczegółów strony docelowej.',
      },
    },
    users: {
      add: 'Dodaj uzytkownika',
      edit: 'Edytuj użytkownika',
      roles: {
        title: 'Usuń użytkownika',
        all: 'Wszystkie funkcje',
        admin: 'Administrator',
        user: 'Uzytkownik',
      },
      columns: {
        name: 'Imię',
        email: 'Email',
        role: 'Stanowisko',
      },
      actions: {
        delete: 'Usuń',
        edit: 'Edytuj',
      },
      deleteConfirm: {
        title: 'Usuń użytkownika',
        message: 'Napewno chcesz usunąć tego użytkownika?',
        buttonOk: 'Usuń',
        buttonCancel: 'Anuluj',
      },
      addToast: {
        title: 'Dodaj użytkownika',
        message: 'Użytkownik został utworzony pomyślnie.',
      },
      editToast: {
        title: 'Edytuj użytkownika',
        message: 'Użytkownik został pomyślnie zaktualizowany.',
      },
      deleteToast: {
        title: 'Usuń użytkownika',
        message: 'Pomyślnie usunięto użytkownika.',
      },
      error: {
        title: 'Wystąpił błąd',
        message: 'Wystąpił błąd podczas zapisywania danych użytkowników.',
      },
    },
    licenseKey: {
      add: 'Dodaj klucz licencji',
      columns: {
        licenseKey: 'Klucz licencji',
        placeholder: 'Wprowadź klucz licencyjny',
        enableCollection: 'Włącz przetwarzanie danych',
      },
      actions: {
        delete: 'Usuń',
      },
      deleteConfirm: {
        title: 'Usuń klucz licencyjny',
        message: 'Czy na pewno chcesz usunąć klucz licencyjny?',
        buttonOk: 'Usuń',
        buttonCancel: 'Anuluj',
      },
      addToast: {
        title: 'Dodaj klucz licencji',
        message: 'Klucz licencyjny dodano pomyślnie.',
      },
      editToast: {
        title: 'Edytuj klucz licencyjny',
        message: 'Klucz licencji został zaaktualizowany pomyślnie.',
      },
      deleteToast: {
        title: 'Usuń klucz licencji',
        message: 'Klucz licencyjny został pomyślnie usunięty.',
      },
      error: {
        title: 'Wystąpił błąd',
        message:
          'Wystąpił błąd podczas zapisywania szczegółów kluczy licencyjnych.',
      },
    },
    landingPage: {
      intro: 'Stwórz swój landing page.',
      copy: {
        label: 'Kopuj adres URL',
        button: 'Kopia',
      },
      logo: 'Logo',
      header: {
        label: 'Nagłówek',
        placeholder: 'Napisz tytuł nagłówka',
      },
      content: {
        label: 'Treść',
        placeholder: 'Napisz opis',
      },
      update: 'Aktualizacja',
      preview: {
        btnTxt: 'Podgląd Landing Page',
        closeBtnTxt: 'Podgląd z bliska',
        signIn: 'Zaloguj się',
      },
      logoAlt: 'Logo firmy',
      notify: {
        title: 'Zaktualizuj stronę docelową',
        message:
          'Strona docelowa nie została jeszcze sprawdzona. Czy chcesz to zrobić teraz?',
        okText: 'Kontynuuj',
        cancelText: 'Na razie pomiń',
      },
    },
  },
  imageUpload: {
    button: 'Przeglądaj',
  },
  countries: {
    empty: 'Wybierz kraj',
    de: 'Niemcy',
    us: 'Stany Zjednoczone',
    es: 'Hiszpania',
  },
  support: {
    title: 'Wsparcie',
    tabs: {
      contact: 'Kontakt',
      downloadApp: 'Pobierz aplikację',
    },
    contact: {
      info: 'Jak możemy pomóc?',
      title: 'Napisz do nas',
      topic: {
        label: 'Temat',
        placeholder: 'Jaki jest twój temat?',
      },
      topics: {
        empty: 'Wybierz temat',
        topicA: 'Temat A',
        topicB: 'Temat B',
        topicC: 'Temat C',
      },
      message: {
        label: 'Wiadomość',
      },
      submit: 'Zgłoś',
      callUs: 'Zadzwoń do nas',
      line: 'Linie wsparcia',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Kontakt E-mail',
      },
    },
    downloadApp: {
      info: 'Aplikacja desktopowa dla Twojego komputera',
      download: 'Ładowanie',
      toastTitle: 'Pobierz aplikację',
      notFound: 'Aplikacja nie jest dostępna',
    },
  },
  account: {
    title: 'Konto',
    tabs: {
      company: 'Informacje o firmie',
      settings: 'Ustawienia',
      profile: 'Profil',
    },
    company: {
      companyInfo: 'Zarządzaj informacjami o swojej firmie.',
      logo: 'Logo firmy',
      information: 'Informacje',
      contact: 'Kontakt',
      address: 'Adres',
      shipping: {
        title: 'Wysyłka',
        address: 'Adres wysyłki',
        sameAsAddress: 'Tak samo jak adres',
        street: {
          label: 'Ulica',
          placeholder: 'Wpisz ulicę wysyłkową',
        },
        city: {
          label: 'Miasto',
          placeholder: 'Wpisz miasto wysyłki',
        },
        zip: {
          label: 'Kod pocztowy',
          placeholder: 'Wprowadź kod pocztowy wysyłki',
        },
      },
      button: 'Aktualizacja',
    },
    settings: {
      info: 'Zarządzaj swoimi ustawieniami osobistymi.',
      password: {
        title: 'Hasło',
        label: 'Nowe hasło',
        placeholder: 'Wpisz nowe hasło',
      },
      passwordConfirmation: {
        label: 'Potwierdź hasło',
        placeholder: 'Wpisz i potwierdź hasło',
      },
      updatePassword: 'Aktualizacja hasła',
      notifications: {
        title: 'Powiadomienia',
        notificationOnNewMessageReceived: 'Otrzymaną nową wiadomość',
        sendEmailOnNewMessageReceived: 'Email przy nowej wiadomości',
        notificationOnNewProjectIsCreated: 'Nowe zamówienie stworzone',
        sendEmailOnNewProjectIsCreated: 'Email przy nowym zamówieniu',
        notificationOnOrderProcessStart: 'Start procesu nowego zamówienia',
        notificationOnOrderProcessCompleted: 'Zakończenie procesu zamówienia',
      },
      language: {
        title: 'Język',
      },
      project: {
        title: 'Projekt',
        folderStructure: 'Pobierz strukturę folderu',
      },
    },
    profile: {
      info: 'Zarządzaj informacjami o swoim profilu.',
      button: 'Aktualizacja',
    },
    updateProfileToast: {
      title: 'Zaktualizować profil',
      message: 'Profil zaktualizowany pomyślnie.',
    },
    updatePasswordToast: {
      title: 'Aktualizować hasło',
      message: 'Hasło zostało zmienione.',
    },
    updateNotificationToast: {
      title: 'Zaktualizuj powiadomienie',
      message: 'Powiadomienie zostało pomyślnie zaktualizowane.',
    },
    updateLanguageToast: {
      title: 'Zaktualizuj język',
      message: 'Język zaaktualizowany pomyślnie.',
    },
    updateCompanyToast: {
      title: 'Zaktualizuj firmę',
      message: 'Firma zaaktualizowana pomyślnie.',
    },
    invalidVAT: {
      title: 'Niepoprawny stan.',
      message: 'Podany stan jest niepoprawny, Proszę wprowadzić poprawny stan.',
    },
  },
  switch: {
    on: 'Włącz',
    off: 'Wyłącz',
  },
  auth: {
    registerConfirm: {
      title: 'Link potwierdzający',
      message:
        'Wysłaliśmy link potwierdzający na Twojego maila. Proszę sprawdzić skrzynkę odbiorczą w celu aktywowania konta.',
      buttonOk: 'Ok',
    },
    confirmationConfirm: {
      title: 'Zarejestruj się',
      message:
        'Witaj na pokładzie{{name}}! Twoje konto jest aktywne. Mozesz już zacząć korzystać z aplikacji.',
      buttonOk: 'Świetnie!',
    },
    forgotConfirm: {
      title: 'Zapomniałem potwierdzenia hasła',
      message: 'Aby zresetować hasło, proszę sprawdzić swojego emaila.',
    },
    resetConfirm: {
      title: 'Zresetować potwierdzenie hasła',
      message: 'Twoje hasło zostało zmienione pomyślnie',
    },
    accountCreationConfirm: {
      title: 'Rejestracja udana!',
      error: 'Błąd rejestracji',
      message:
        'Witaj na pokładzie{{name}}! Twoje konto jest aktywne. Mozesz już zacząć korzystać z aplikacji.',
      buttonOk: 'Świetnie',
    },
    millCenterAdded: {
      title: 'Centrum frezarskie zostało dodane',
      message: "Udało Ci się dołączyć do '{{millName}}' centrum frezowania.",
      buttonOk: 'Świetnie',
    },
  },
  reset: {
    title: 'Zresetuj hasło',
    info: 'Proszę wpisać nowe hasło',
    button: 'Zmień swoje hasło',
  },
  accountCreation: {
    title: 'Tworzenie konta',
    info: 'Aby zakończyć tworzenie konta, należy utworzyć hasło.',
    password: {
      label: 'Utwórz hasło',
      placeholder: 'Wprowadź hasło',
    },
    button: 'Aktywuj konto',
  },
  invitation: {
    title: 'Zaproszenie',
    emailLabel: 'Email',
    message: "Zostałeś/aś zaproszony/a do '{{millName}}' miejsca pracy.",
    submitBtnText: 'Utwórz nowe konto',
    connectToAccount: 'Połącz się z istniejącym kontem',
    connectToAccountMsg:
      'Jeśli masz już istniejące konto hyperflow, możesz dodać do niego ten obszar roboczy.',
    or: 'Albo',
    addMillToAccountTxt: 'Dodaj obróbkę do tego konta',
    createNewAccount: 'Utwórz nowe konto',
    sendConfirmationEmail: 'Wyślij e-mail z potwierdzeniem',
    existingAccount: 'Istniejące konto',
    existingAccountMsg:
      'Wprowadzony adres e-mail jest powiązany z istniejącym kontem hyperconnect. Możesz dodać nowy obróbkę do tego konta lub utworzyć nowe konto używając innego adresu e-mail.',
  },
  landingPage: {
    signIn: 'Zaloguj się',
  },
  unsavedChanges: {
    message: 'Ten plik ma niezapisane zmiany. Napewno chcesz kontynuować?',
  },
  contract: {
    title: 'Kontrakt',
    error: 'Wystąpił błąd',
    tabs: {
      contractType: {
        title: 'Typ kontraktu',
        subscribe: 'Subskrybuj',
        unsubscribe: 'Zrezygnuj z subskrypcji',
        resubscribe: 'Wznów subskrypcję',
        addOnConfirmation:
          'Czas przechowywania plików na {{addOnDuration}} dni (€{{getPromotionalPrice}}/month) został dodany do twojej sybskrypcji i zostanie obciążony przy następnej fakturze.',
        addOnUnsubscription:
          'Podstawowy dodatek z minimalnym czasem przechowywania plików został wybrany w Twoim planie.',
        subscriptionSuccess: 'Pomyślnie zapisałeś się do wybranego planu',
        subscriptionFailure:
          'Wystąpił błąd podczas zapisywania się do wybranego planu. Proszę skontaktować się z administratorem lub spróbować ponownie później.',
        unsubscriptionSuccess: 'Pomyślnie wypisałeś się z wybranego planu.',
        unsubscriptionFailure:
          'Wystąpił błąd podczas wypisywania się z wybranego planu. Proszę skontaktować się z administratorem lub spróbować ponownie później.',
        resubscriptionSuccess: 'Pomyślnie wznowiono Twój plan.',
        resubscriptionFailure:
          'Wystąpił błąd podczas wznawiania Twojego planu. Proszę skontaktuj się z administratorem albo spróbuj ponownie pózniej',
        month: 'Miesiąc',
        cancelsAt: 'Subskrypcja wygasa w dniu: ',
        free: 'Darmowy',
        silver: 'Srebrny',
        gold: 'Złoty',
        basic: 'Podstawowy',
        essential: 'Istotne',
        enterprise: 'Przedsiębiorstwo',
        custom: 'Niestandardowe',
        storage: '{{dataLimit}} Mbs pamięci masowej w chmurze',
        orders: 'Tworzenie do {{ordersLimit}} zamówień',
        clients: 'Zaproś do {{clients}} klientów',
        supportAvailable: 'Wsparcie jest dostępne',
        supportNotAvailable: 'Wsparcie nie jest dostępne',
        cancel: 'anulować',
        confirm: 'potwierdzić',
        upgrade: 'aktualizacja',
        cancelConfirmation: 'Czy na pewno chcesz anulować swoją subskrypcję?',
        resubscriptionConfirmation: 'Napewno chcesz wznowić swoją subskrypcję?',
        subscriptionConfirmation:
          'Twoja sybskrypcja zostanie ponowiona i ulepszona. Napewno chcesz potwierdzić?',
        upgradesubscriptionConfirmation:
          'Napewno chcesz zmienić/ulepszyć Twoją subskrypcję?',
        subscriptionConsent:
          'Dziękujemy za wybranie subskrypcji hyperDENT Connect, Wyraź zgodę aby kontynuować.',
        payment: {
          success:
            'Twoja płatność została pomyślnie przyjęta. Wkrótce zostaniesz przekierowany.',
          failure:
            'Proces płatności nie powiódł się z powodu ręcznego przerwania lub nieprawidłowych danych karty. Zostaniesz wkrótce przekierowany.',
        },
      },
      usageDetail: {
        title: 'Detale użytkowania',
        resourceName: 'Nazwa zasobu',
        used: 'Używany',
        limit: 'Limit',
        usagePercentage: 'Wykorzystane Procent',
        errorMessage: 'Wystąpił błąd podczas ładowania szczegółów użycia.',
      },
      billing: {
        title: 'Adres rozliczeniowy',
        custEmail: 'E-mail klienta',
        custComp: 'Nazwa firmy',
        fromPeriod: 'Okres Od',
        toPeriod: 'Okres do',
        amount: 'Kwota',
        errorMessage: 'Wystąpił błąd podczas ładowania adresu rozliczeniowego.',
        manage: 'Zarządzaj adresem rozliczeniowym',
      },
      invoices: {
        title: 'Faktury',
        id: 'Id faktury',
        itemDesc: 'Opis',
        amountDue: 'Kwota należna',
        download: 'Ładowanie',
        isPaid: 'Jest płatny',
        paid: 'Płatne',
        due: 'Należy',
        errorMessage: 'Wystąpił bład podczas ładowania list faktur.',
        totalAmountDue: 'Suma do zapłaty',
        dateDue: 'Data opłaty',
        upcoming: 'Nadchodząca faktura',
        buttonOk: 'Ok',
      },
    },
    addons: {
      title: 'Dodatki',
      keepFiles: 'Czas przechowywania plików',
      days: 'dni',
      month: 'miesiące',
    },
  },
  errorBoundary: {
    errorMessage: 'Oops!, coś poszło nie tak.',
    errorFix: 'Błąd techniczny, pracujemy nad jego usunięciem',
  },
  notifications: {
    label: 'Powiadomienia',
    new: 'Nowe powiadomienie',
    none: 'Nie masz żadnych powiadomień',
    markAsUnread: 'Oznacz jako nieprzeczytane',
    filter: {
      all: 'Wszystko',
      unread: 'Nieprzeczytane',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Błąd',
        message: 'Coś poszło nie tak podczas generowania raportu PDF!',
      },
      success: {
        title: 'Sukces',
        message: 'Raport PDF został wygenerowany pomyślnie',
      },
    },
    dateFormat: 'Format daty raportu',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    dongleId: 'Dongle Id',
    isNetwork: 'Is Network',
    isCommercial: 'Is Commercial',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
  dealerManagement: {
    dealerHome: {
      heading: ' Welcome to the dealers dashboard ',
      navParagraph:
        'You can go to any of the following dashboard views by navigating from the sidebar or from the below mentioned links',
      navMenu: {
        dealerInformation: 'Dealer Information',
        dealerTickets: 'Dealer Tickets',
        dealerSettings: 'Dealer Settings',
      },
    },
    heading: 'Dealers Management',
    invitation: 'Invite as User',
    reInvite: 'Reinvite User',
    newUser: 'New Dealer',
    dealerId: 'Dealer Id',
    name: 'Name',
    configurations: {
      subDealerAllowed: 'Can Create Sub Dealers',
      ownConfigAllowed: 'Can Set Own Configurations',
      parentDealerId: 'Parent Dealer Id',
      parentDealerName: 'Parent Dealer Name',
      heading: 'Configurations',
      baseURL: 'Components Base URL',
      configType: 'Config Type',
      json: 'Configurations Json',
      file: 'Configurations File',
      url: 'Configurations Url',
    },
    contact: {
      dealerContact: 'Dealer Contact',
      supportContact: 'Support Contact',
      salesContact: 'Sales Contact',
      address1: 'Address 1',
      address2: 'Address 2',
      address3: 'Address 3',
      zipCode: 'Zip Code',
      city: 'City',
      district: 'District',
      state: 'State',
      country: 'Country',
      phone: 'Phone',
      email: 'Email',
      webUrl: 'Web Url',
      showInfoToOutOfMaintenanceUsers: 'Show Info To Out Of Maintenance Users',
    },
    configOptions: {
      none: 'None',
      fixedJson: 'Fixed Json',
      fixedFile: 'Fixed File',
      dynamicUrl: 'Dynamic Url',
    },
    buttonSave: 'Save',
    user: {
      heading: 'User Management',
      newUser: 'New User',
      dealer: 'Dealer',
      manager: 'Manager',
      columns: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
      },
    },
    updateConfirm: {
      title: 'Update E-Mail',
      message:
        'User will need to use this email to log in later. Please confirm?',
      messageSelf:
        'You are updating your email. You will be logged out immediately and can log in later with the updated email after confirmation. Do you want to proceed?',
      buttonOk: 'Update',
      buttonCancel: 'Cancel',
    },
    requiredDealerId: 'Dealer Id is required',
    requiredName: 'Dealer Name is required',
    requiredConfigType: 'Configuration Type is required',
    invalidJson: 'Invalid JSON',
    requiredParentEmail: 'Parent Dealer Id or Email is required',
    emailNotValid: 'Email is not valid',
    tickets: {
      title: 'Dealer Tickets',
      ticketsFound: '{{totalSize}} ticket(s) found',
      dataColumns: {
        ticketId: 'Ticket Id',
        activationStatus: 'Activation Status',
        productText: 'Product Text',
        expiryDate: 'Expiry Date',
        licenseQuantity: 'Quantity',
        maintenanceExpiry: 'Maintenance Expiry',
        parentDealerId: 'Parent Dealer Id',
        dealerId: 'Dealer Id',
        isUpdateAvailable: 'Update Available',
        minimumExpiryDate: 'Minimum Expiry Date',
      },
      pageSize: 'Page Size',
      noActiveTicketsFound:
        'No active product detail found against ticketId "{{ticketId}}"',
      dealersList: 'Dealers List',
    },
    settings: {
      title: 'Settings',
      pageSize: 'Page Size',
      language: 'Language',
    },
  },
  hdUsageInfo: {
    usageInfo: 'hyperDENT Usage Info',
    runningMachines: 'Running Machines',
    concurrentUsers: 'Concurrent Users',
    lastUpdateReceivedAt: 'Last Update Received At',
    licenseKey: 'License Key',
    allowedUsers: 'Allowed Network Users',
    mulipleLogins: 'Multiple Logins',
    firstPing: 'First Ping Received At',
    lastPing: 'Recent Ping Received At',
    concurrentlyUsedCount: 'Concurrently Used Count',
  },
  unknownError: 'An unknown error occured',
  dealerConfig: {
    test: 'Dealer configuration test result',
    invalidTicketFormat:
      'Invalid ticket format. Expected format: {{HD_TICKET_FORMAT_STR}}',
    response: 'Response will be shown here',
    wrapResponse: 'Wrap Response Text',
    initialCall: 'Initial Call',
  },
};
