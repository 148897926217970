import React from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { HomeIcon, LogoIcon } from 'icons';
import { authSlice } from 'slices';
import { DEALER_HOME_ROUTE, ROOT_ROUTE } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const DealerHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { dealer } = useAppSelector((state) => state.auth.data);
  const { t } = useTranslation();

  const logout = () => {
    localStorage.clear();
    dispatch(authSlice.actions.gotoHome({ redirectTo: ROOT_ROUTE }));
  };

  const navigateToHome = () => {
    if (location.pathname === DEALER_HOME_ROUTE) return;
    dispatch(authSlice.actions.gotoHome({ redirectTo: DEALER_HOME_ROUTE }));
  };

  return (
    <Navbar expand="md" className="h-100 user-navbar">
      <div style={{ position: 'absolute' }}>
        <Button className="mr-30" onClick={navigateToHome}>
          <HomeIcon />
        </Button>
        <strong className="color-gray">{t('dealerManagement.name')}: </strong>{' '}
        {`${dealer?.name} `}
        <strong className="color-gray">
          {t('dealerManagement.dealerId')}:{' '}
        </strong>
        {`${dealer?.dealerId}`}
      </div>
      <div className="user-navbar-brand">
        <LogoIcon />
      </div>
      <Navbar.Collapse
        className="justify-content-end m-5"
        style={{ width: '200%' }}
      >
        <Nav.Link style={{ color: '#3A3F4B' }} role="button" onClick={logout}>
          <strong>Sign out</strong>
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};
