import React from 'react';
import { Card } from 'antd';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Spinner2 } from 'components/Spinner2';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import { Modal } from '../Modal';

interface IUserObjectProps {
  show: boolean;
  selectedDealer?: IDealerItem;
  userSaveLoading: boolean;
  onClose: () => any;
  onSubmit: (e: string) => void | Promise<any>;
}

export const InviteUsers = ({
  show,
  selectedDealer,
  userSaveLoading,
  onClose,
  onSubmit,
}: IUserObjectProps) => {
  const [email, setEmail] = React.useState(selectedDealer?.email || '');
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const inviteUser = (e: string) => {
    onSubmit(e);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      inviteUser(email);
    }
  };

  const addUser = () => {
    inviteUser(email);
  };

  React.useEffect(() => {
    setEmail(selectedDealer?.email || '');
  }, [selectedDealer]);

  return (
    <Modal
      title="Invite User"
      show={show}
      onClose={onClose}
      closeOnOutSideClick={false}
      size="lg"
    >
      <Card className="mb-10">
        <Spinner2 show={userSaveLoading} />
        <Row>
          <Col md={12}>
            <Form>
              <Form.Label className="col-sm-3 mr-20 text-left invite-model-label">
                Enter Email
              </Form.Label>
              <input
                type="email"
                className="form-control form-control-bordered search-input search-text mb-10"
                name="search"
                placeholder="Enter Email"
                value={email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </Form>
          </Col>
        </Row>
      </Card>
      <Button onClick={addUser} style={{ width: '100%' }}>
        Send Invitation
      </Button>
    </Modal>
  );
};

InviteUsers.defaultProps = {
  selectedDealer: undefined,
};
