/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useManagerRole } from 'hooks/role';
import { getPageSize } from '../../../Manager/Settings';
import { columns } from './columns/columns';
import { IDealerItem } from '../Models/dealers';
import { managerColumns } from './columns/managerColumns';

interface IDealerListView {
  dealerData: IDealerItem[];
  selectedDealer?: IDealerItem;
  handleOnSelect: (row: any) => void;
}

export const DealerListView = ({
  dealerData = [],
  handleOnSelect,
  selectedDealer,
}: IDealerListView) => {
  const { SearchBar } = Search;
  const [pageSize] = useState<number>(+getPageSize());
  const isManager = useManagerRole();
  // Function to change row color on selection
  const customRowHighlighter = (row: any) =>
    row.id === selectedDealer?.id ? 'row-highlight' : '';

  const selectRow: SelectRowProps<any> = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (row: any) => {
      handleOnSelect(row);
    },
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={dealerData}
      columns={isManager ? managerColumns : columns}
      search
    >
      {(props) => (
        <div>
          <SearchBar
            {...props.searchProps}
            className="custome-search-field form-control-bordered"
            style={{ color: 'grey' }}
            delay={1000}
            placeholder="Search! "
          />
          <hr />
          <BootstrapTable
            {...props.baseProps}
            selectRow={selectRow}
            rowClasses={customRowHighlighter}
            pagination={paginationFactory({
              sizePerPage: pageSize,
              hidePageListOnlyOnePage: true,
            })}
            wrapperClasses="table-dealer-content"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

DealerListView.defaultProps = {
  selectedDealer: undefined,
};
