import React from 'react';
import { Button, Select, Switch } from 'components';
import {
  CONFIGURATION_TYPE,
  FILE_TYPES,
  TAB_FORM_IS_DIRTY,
} from 'constants/constants';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { UploadComponent } from 'components/Upload/UploadComponent';
import i18n from 'translations';
import { useBranchDealerSelf, useManagerRole } from 'hooks/role';
import { setSessionStorage } from 'utils/localStorage';
import { Prompt } from 'react-router-dom';
import { IDealerInfo } from 'slices/auth';
import { IDealerObject } from '../Models/dealers';

interface IDealerDetailFormProps {
  detailData: IDealerObject;
  rootDealer?: IDealerInfo;
  validationSchema: any | (() => any);
  onSubmit: (
    values: IDealerObject,
    formik: FormikHelpers<IDealerObject>
  ) => void | Promise<any>;
}

export const DealersDetailView = ({
  detailData,
  validationSchema,
  rootDealer,
  onSubmit,
}: IDealerDetailFormProps) => {
  const { t } = useTranslation();
  const self = useBranchDealerSelf(detailData.userId);
  const isManager = useManagerRole();

  const CONFIGOPTIONS = [
    {
      value: 'None',
      label: i18n.t('dealerManagement.configOptions.none'),
    },
    {
      value: 'FixedJson',
      label: i18n.t('dealerManagement.configOptions.fixedJson'),
    },
    {
      value: 'FixedFile',
      label: i18n.t('dealerManagement.configOptions.fixedFile'),
    },
    {
      value: 'DynamicUrl',
      label: i18n.t('dealerManagement.configOptions.dynamicUrl'),
    },
  ];

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={detailData}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
          dirty,
        }) => {
          setSessionStorage(TAB_FORM_IS_DIRTY, `${dirty}`);
          let showConfigSection = true;
          if (
            !!self &&
            !!values.id &&
            !values?.configuration?.permissions?.ownConfigurationsAllowed &&
            !values?.configuration?.permissions?.subDealerAllowed
          ) {
            showConfigSection = false;
          }

          return (
            <>
              <Prompt when={dirty} message={t('unsavedChanges.message')} />
              {Object.keys(errors).length > 0 &&
                Object.entries(errors).map(([key, value]) => (
                  <div key={key} className="alert alert-danger">
                    {value}
                  </div>
                ))}
              <Form
                onSubmit={handleSubmit}
                className="form-control-bordered"
                data-cy="dealer-profile"
              >
                <Row>
                  <Col md={10}>
                    <Form.Group
                      controlId="dealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.dealerId')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="dealerId"
                        placeholder={t('dealerManagement.dealerId')}
                        value={values?.dealerId}
                        onChange={handleChange}
                        isInvalid={touched.dealerId && !!errors.dealerId}
                        disabled={!!detailData?.id && !isManager}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="name"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.name')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="name"
                        placeholder={t('dealerManagement.name')}
                        value={values?.name ?? ''}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="parentDealerId"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.configurations.parentDealerId')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="parentDealerId"
                        placeholder={t(
                          'dealerManagement.configurations.parentDealerId'
                        )}
                        value={values?.parentDealerId ?? ''}
                        onChange={handleChange}
                        disabled={!!rootDealer || !!detailData?.id}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="parentDealerName"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('dealerManagement.configurations.parentDealerName')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        title={values?.parentDealerName ?? ''}
                        value={values?.parentDealerName ?? ''}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="email"
                      className="form-inline form-inline-dealer"
                    >
                      <Form.Label className="col-sm-3 mr-20 text-left ml-20">
                        {t('email.label')}
                      </Form.Label>
                      <Form.Control
                        className="col-sm-5 form-control-bordered margin-input"
                        name="email"
                        placeholder={t('email.label')}
                        value={values?.email ?? ''}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        disabled={!!detailData?.id}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="logo" className="mb-36">
                      <UploadComponent
                        key={Date.now()}
                        setFieldValue={setFieldValue}
                        accept="image/*"
                        values={values?.files?.logo}
                        propField="files.logo"
                        fileType={FILE_TYPES.IMAGE}
                        url={detailData?.logo?.url}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Accordion>
                  {showConfigSection && (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="button"
                          eventKey="0"
                          style={{
                            backgroundColor: 'lavender',
                            width: '100%',
                          }}
                        >
                          {t('dealerManagement.configurations.heading')}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="overflow-auto">
                          <Row>
                            <Col md={3} />
                            <Col className="ml-10">
                              <Form.Group controlId="configuration.subDealerAllowed">
                                {/* key and id field must be id {string} in switch for working properly */}
                                <Switch
                                  key={values.id.toString()}
                                  label={t(
                                    'dealerManagement.configurations.subDealerAllowed'
                                  )}
                                  id={values.id.toString()}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'configuration.permissions.subDealerAllowed',
                                      e.target.checked
                                    );
                                  }}
                                  defaultChecked={
                                    !!values.configuration?.permissions
                                      ?.subDealerAllowed
                                  }
                                  className="div-switch"
                                  disabled={!!self}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4} />
                          </Row>
                          <Row className="mb-10">
                            <Col md={3} />
                            <Col className="ml-10">
                              <Form.Group controlId="configuration.ownConfigurationsAllowed">
                                <Switch
                                  key={`ownConfig${values.id}`}
                                  label={t(
                                    'dealerManagement.configurations.ownConfigAllowed'
                                  )}
                                  id={`ownConfig${values.id}`}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'configuration.permissions.ownConfigurationsAllowed',
                                      e.target.checked
                                    );
                                  }}
                                  defaultChecked={
                                    !!values.configuration?.permissions
                                      ?.ownConfigurationsAllowed
                                  }
                                  className="div-switch"
                                  disabled={!!self}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Group
                            controlId="configuration.componentBaseURL"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              {t('dealerManagement.configurations.baseURL')}
                            </Form.Label>
                            <Form.Control
                              className="col-sm-5 form-control-bordered"
                              name="configuration.componentBaseURL"
                              placeholder={t(
                                'dealerManagement.configurations.baseURL'
                              )}
                              value={
                                values?.configuration.componentBaseURL
                                  ? values?.configuration.componentBaseURL
                                  : ''
                              }
                              onChange={handleChange}
                              disabled={
                                !!self &&
                                !values.configuration?.permissions
                                  ?.ownConfigurationsAllowed
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="configuration.configurationType"
                            className="form-inline form-inline-dealer"
                          >
                            <Form.Label className="col-sm-3 mr-20 text-left">
                              {t('dealerManagement.configurations.configType')}
                            </Form.Label>
                            <div className="col-sm-5 form-control-bordered padding-zero">
                              <Select
                                value={CONFIGOPTIONS.find(
                                  (opt) =>
                                    opt.value ===
                                    (values?.configuration?.configurationType ??
                                      'None')
                                )}
                                onChange={(configType: any) => {
                                  setFieldValue(
                                    'configuration.configurationType',
                                    configType.value
                                  );
                                }}
                                options={CONFIGOPTIONS}
                                isDisabled={
                                  !!self &&
                                  !values.configuration?.permissions
                                    ?.ownConfigurationsAllowed
                                }
                              />
                            </div>
                          </Form.Group>
                          {values?.configuration?.configurationType ===
                            CONFIGURATION_TYPE.FIXED_JSON && (
                            <Form.Group
                              controlId="configuration.fixedConfigJson"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 mr-20 text-left">
                                {t('dealerManagement.configurations.json')}
                              </Form.Label>
                              <Form.Control
                                name="configuration.fixedConfigJson"
                                placeholder={t(
                                  'dealerManagement.configurations.json'
                                )}
                                value={values.configuration?.fixedConfigJson}
                                onChange={handleChange}
                                data-cy="lp-content"
                                as="textarea"
                                isInvalid={
                                  !!errors.configuration?.fixedConfigJson
                                }
                                className="col-sm-5 form-control-bordered jsonObject"
                                disabled={
                                  !!self &&
                                  !values.configuration?.permissions
                                    ?.ownConfigurationsAllowed
                                }
                              />
                            </Form.Group>
                          )}
                          {values?.configuration?.configurationType ===
                            CONFIGURATION_TYPE.FIXED_FILE && (
                            <Form.Group className="form-inline form-inline-dealer">
                              <Form.Label className="col-sm-3 mr-20 text-left">
                                {t('dealerManagement.configurations.file')}
                              </Form.Label>
                              <UploadComponent
                                setFieldValue={setFieldValue}
                                accept=".json"
                                values={values?.files?.configFile}
                                fileType={FILE_TYPES.FILE}
                                propField="files.configFile"
                                path={
                                  values?.fixedConfigFile?.name ||
                                  values?.files?.configFile[0]?.name
                                }
                                disable={
                                  !!self &&
                                  !values.configuration?.permissions
                                    ?.ownConfigurationsAllowed
                                }
                              />
                            </Form.Group>
                          )}
                          {values?.configuration?.configurationType ===
                            CONFIGURATION_TYPE.DYNAMIC_URL && (
                            <Form.Group
                              controlId="configuration.dynamicConfigUrlPath"
                              className="form-inline form-inline-dealer"
                            >
                              <Form.Label className="col-sm-3 mr-20 text-left">
                                {t('dealerManagement.configurations.url')}
                              </Form.Label>
                              <Form.Control
                                className="col-sm-5 form-control-bordered"
                                name="configuration.dynamicConfigUrlPath"
                                disabled={
                                  !(
                                    values?.configuration?.configurationType ===
                                    CONFIGURATION_TYPE.DYNAMIC_URL
                                  ) ||
                                  (!!self &&
                                    !values.configuration?.permissions
                                      ?.ownConfigurationsAllowed)
                                }
                                placeholder={t(
                                  'dealerManagement.configurations.url'
                                )}
                                value={
                                  values?.configuration?.dynamicConfigUrlPath
                                    ? values?.configuration
                                        ?.dynamicConfigUrlPath
                                    : ''
                                }
                                onChange={handleChange}
                              />
                            </Form.Group>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="1"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.dealerContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <Form.Group
                          controlId="dealerContact.address1"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address1')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address1"
                            placeholder={t('dealerManagement.contact.address1')}
                            value={values?.dealerContact?.address1 ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address2"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address2')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address2"
                            placeholder={t('dealerManagement.contact.address2')}
                            value={values?.dealerContact?.address2 ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.address3"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.address3')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.address3"
                            placeholder={t('dealerManagement.contact.address3')}
                            value={values?.dealerContact?.address3 ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.zipCode"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.zipCode')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.zipCode"
                            placeholder={t('dealerManagement.contact.zipCode')}
                            value={values?.dealerContact?.zipCode ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.city"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.city')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.city"
                            placeholder={t('dealerManagement.contact.city')}
                            value={values?.dealerContact?.city ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.district"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.district')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.district"
                            placeholder={t('dealerManagement.contact.district')}
                            value={values?.dealerContact?.district ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.state"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.state')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.state"
                            placeholder={t('dealerManagement.contact.state')}
                            value={values?.dealerContact?.state ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.country"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.country')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.country"
                            placeholder={t('dealerManagement.contact.country')}
                            value={values?.dealerContact?.country ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.dealerContact?.phone ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.dealerContact?.email ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="dealerContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="dealerContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.dealerContact?.webUrl ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="2"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.supportContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <Form.Group
                          controlId="supportContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.supportContact?.phone ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.supportContact?.email ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="supportContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="supportContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.supportContact?.webUrl ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Row className="mb-10">
                          <Col md={3} />
                          <Col className="ml-10">
                            <Form.Group controlId="showInfoToOutOfMaintenanceUsers">
                              <Switch
                                key={`showInfo${values.id}`}
                                label={t(
                                  'dealerManagement.contact.showInfoToOutOfMaintenanceUsers'
                                )}
                                id={`showInfo${values.id}`}
                                onChange={(e) => {
                                  setFieldValue(
                                    'supportContact.permissions.showInfoToOutOfMaintenanceUsers',
                                    e.target.checked
                                  );
                                }}
                                defaultChecked={
                                  !!values.supportContact?.permissions
                                    ?.showInfoToOutOfMaintenanceUsers
                                }
                                className="div-switch"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="button"
                        eventKey="3"
                        style={{
                          backgroundColor: 'lavender',
                          width: '100%',
                        }}
                      >
                        {t('dealerManagement.contact.salesContact')}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <Form.Group
                          controlId="salesContact.phone"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.phone')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.phone"
                            placeholder={t('dealerManagement.contact.phone')}
                            value={values?.salesContact?.phone ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.email"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.email')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.email"
                            placeholder={t('dealerManagement.contact.email')}
                            value={values?.salesContact?.email ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="salesContact.webUrl"
                          className="form-inline form-inline-dealer"
                        >
                          <Form.Label className="col-sm-3 mr-20 text-left">
                            {t('dealerManagement.contact.webUrl')}
                          </Form.Label>
                          <Form.Control
                            className="col-sm-5 form-control-bordered"
                            name="salesContact.webUrl"
                            placeholder={t('dealerManagement.contact.webUrl')}
                            value={values?.salesContact?.webUrl ?? ''}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Row className="mt-8">
                  <Col md={10} />
                  <Col md={2}>
                    <Button
                      dimension="lg"
                      type="submit"
                      className="search-btn create-button ml-10"
                      disabled={
                        rootDealer &&
                        !rootDealer?.permissions?.subDealerAllowed &&
                        !values?.id &&
                        !self
                      }
                    >
                      {t('dealerManagement.buttonSave')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

DealersDetailView.defaultProps = {
  rootDealer: undefined,
};
