export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Alemán',
    en: 'Inglés',
    it: 'Italiano',
    fr: 'Francés',
    sp: 'Español',
    pl: 'Polaco',
  },
  search: 'Búsqueda',
  goBack: 'Atrás',
  signIn: {
    title: 'Iniciar sesión',
    dontHaveAccount: '¿No tienes una cuenta?',
  },
  signUp: {
    title: 'Inscribirse',
    info:
      'Al registrarse, aceptas nuestra <0>{{privacy}}</0> y <1>{{terms}}</1>.',
    promotions:
      'No quiero recibir actualizaciones ni promociones por correo electrónico',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    serviceterms: 'Acepto el <0>{{service}}</0>.',
    dataprocessing: 'Acepto el <0>{{agreement}}</0>.',
  },
  signOut: {
    title: 'Desconectar',
  },
  email: {
    label: 'Dirección de correo electrónico',
    placeholder: 'Dirección de correo electrónico',
    taken: 'El correo electrónico ya está ocupado',
  },
  password: {
    label: 'Contraseña',
    placeholder: 'Introducir la contraseña',
    mismatch: 'Las contraseñas deben coincidir.',
  },
  passwordConfirmation: {
    label: 'Confirmar contraseña',
    placeholder: 'Ingrese confirmar contraseña',
  },
  firstName: {
    label: 'Nombre',
    placeholder: 'Indique su nombre',
  },
  lastName: {
    label: 'Apellidos',
    placeholder: 'indique sus apellidos',
  },
  activationKey: {
    label: 'Clave de activación',
    placeholder: 'Introduzca la clave de activación',
  },
  companyName: {
    label: 'Nombre de empresa',
    placeholder: 'Introduzca el nombre de la empresa',
  },
  contactPerson: {
    label: 'Persona de contacto',
    placeholder: 'Ingrese el nombre de la persona de contacto',
  },
  country: {
    label: 'País',
  },
  address: {
    label: 'Dirección',
    placeholder: 'Añadir dirección',
  },
  city: {
    label: 'Ciudad',
    placeholder: 'Añadir ciudad',
  },
  zip: {
    label: 'Código postal',
    placeholder: 'Añadir código postal',
  },
  web: {
    label: 'Web',
    placeholder: 'Añadir web',
  },
  uid: {
    label: 'TaxId',
    placeholder: 'Añadir tax ID',
  },
  vatId: {
    label: 'Num IVA',
    placeholder: 'IVA (e.g: E123456789)',
  },
  state: {
    label: 'Provincia',
    placeholder: 'Añadir provincia',
  },
  street: {
    label: 'Calle',
    placeholder: 'Introduzca la calle',
  },
  phone: {
    label: 'Teléfono',
    placeholder: 'Añadir teléfono',
  },
  forgot: {
    title: 'Olvidé mi contraseña',
    info:
      '¡No te preocupes! Simplemente complete su correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
    button: 'Enviar restablecimiento de contraseña',
    password: '¿Se te olvidó tu contraseña?',
  },
  header: {
    profile: 'Perfil',
    settings: 'Ajustes',
    companyInfo: 'Información de la compañía',
    selectMill: 'Seleccione centro de fresado',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'Sobre nosotros',
        content:
          'Follow-Me!Technology Group desarrolla y distribuye hyperDENT®. Un sistema software que cubre todo el proceso productivo con un único software CAM. La sede de SÍGUEME! Technology GmbH tiene su sede en Munich y nuestro CEO, el Dr. Ferdinand Schwenkert, y el CTO, Günter Linseis, innovan constantemente en nuevas soluciones inteligentes en toda nuestra gama de productos, manteniendo una perspectiva de futuro. <br> <br> Nuestras oficinas subsidiarias de SÍGUEME! Technology Group está presente en todo el mundo, como Italia, China, Japón, Corea, Singapur y Estados Unidos.',
        ceoMessage:
          '“Queremos ofrecer soluciones de software dental que creen valor mejorando los procesos de producción a través de la digitalización y la automatización.”',
      },
      contact: {
        title: 'Contacto',
        content:
          '<strong>FOLLOW-ME! TECHNOLOGY GmbH, Regerstrasse 27, 81541 Munich, Germany. <br> Tel.: +49 89 452 170 60 . <br> E-Mail: info@follow-me-tech.com</strong>',
      },
      desktopApp: {
        title: 'Aplicación de escritorio',
        content:
          'Mayor productividad, más flexibilidad, más espacio de almacenamiento y procesos más eficientes.',
      },
      privacyPolicy: {
        title: 'Política de privacidad',
        content:
          '<strong>La protección de datos es un asunto de gran importancia para FOLLOW-ME! Tecnología GmbH. El uso de nuestro sitio web generalmente es posible sin la necesidad de proporcionar datos personales. Los datos personales solo se procesan en la medida necesaria para la capacidad de proporcionar un sitio web que funcione y el contenido y los servicios que se ofrecen a través de él. El procesamiento de datos personales de nuestros usuarios normalmente solo se lleva a cabo con el consentimiento previo del usuario. Existe una excepción a esto en los casos en que no es posible obtener el consentimiento por adelantado por razones de hecho y cuando el procesamiento de los datos está permitido por las disposiciones legales.</strong>',
      },
      termsOfService: {
        title: 'Términos de servicio',
        content:
          '<strong>Actualmente estamos trabajando en nuestros términos de servicio actualizados; consúltelos más tarde para ver los términos de servicio actualizados para usar hyperDENT Connect.</strong>',
      },
      dataProcessing: {
        title: 'Acuerdo de procesamiento de datos',
        content:
          '<strong>Actualmente estamos trabajando en nuestro acuerdo de procesamiento de datos actualizado; consúltelo más tarde para ver el acuerdo de procesamiento de datos actualizado para usar hyperDENT Connect.</strong>',
      },
    },
  },
  home: {
    home: 'Casa',
    subtitle: 'hyperDENT Connect',
    title: 'Conectado con expertos',
    content:
      'Comparta sus restauraciones dentales digitales de la manera más fácil.',
    reasons: {
      title: '¿Por qué utilizar hyperDENT Connect?',
      reason1: {
        title: 'La odontología digital está cambiando los requisitos. ',
        content:
          'Mayor productividad, más flexibilidad, más espacio de almacenamiento y procesos más eficientes.',
      },
      reason2: {
        title: 'Personaliza tus servicios en la nube.',
        content:
          'Los servicios en la nube hechos a medida, de alto rendimiento y escalables abren posibilidades completamente nuevas para que los centros de fresado logren sus propios objetivos.',
      },
      reason3: {
        title: 'Fácil acceso a la nube ',
        content:
          'hyperDENT Connect le brinda acceso a los archivos de sus clientes y utiliza la aplicación desde cualquier dispositivo que pueda acceder a Internet.',
      },
    },
    policiesupdate: {
      title: 'Actualizaciones de políticas',
      content:
        'Por favor, dé su consentimiento sobre nuestros términos de servicio y acuerdo de procesamiento de datos actualizados.',
    },
  },
  welcome: {
    title: 'Bienvenido a hyperDENT Connect',
    content:
      'hyperDENT Connect es su plataforma para compartir archivos dentales digitales. El intercambio sin esfuerzo para los clientes de millingcenter, el informe de estado en tiempo real y la comunicación sencilla conectan a millingcenters con sus clientes. ',
    landingPage: {
      title: '1. Crear página de destino',
      content: '',
      button: 'Configuración de la página de destino',
    },
    inviteClients: {
      title: '2. Invitar clientes',
      content:
        'Agregue laboratorios o clínicas dentales a sus cuentas de clientes invitándolos con un enlace de invitación predefinido. ',
      button: 'Invitar clientes',
    },
  },
  labWelcome: {
    title: 'Titular instructivo',
    content: 'Título instructivo: Texto aquí',
    serviceApp: {
      title: 'Aplicación de servicio',
      content: '',
      button: 'Descarga la App',
    },
    dashboard: {
      title: 'Panel',
      content: '',
      button: 'Ir al Panel',
    },
  },
  orders: {
    title: 'Pedidos',
    deleted: 'Eliminados',
    deletedFilesInfo:
      'Los archivos de este proyecto se han eliminado porque se alcanzó el límite de mantenimiento de archivos.',
    noSearch: 'Sin resultados de búsqueda',
    noOrders: 'No se han creado pedidos',
    winApp: 'Los pedidos se crean desde la aplicación de Windows',
    downloadApp: 'Descarga la aplicación',
    columns: {
      orderId: 'Id Pedido',
      name: 'Nombre del Pedido',
      date: 'Fecha',
      messages: 'Mensajes',
      components: 'Componentes',
      client: 'Cliente',
      responsible: 'Responsable del proyecto',
      download: 'Descarga',
      report: 'Informe',
    },
    component: {
      columns: {
        name: 'Componente',
        status: 'Estado',
        messages: 'Mensajes',
        files: 'Archivos',
        comment: 'Comentario',
      },
    },
    messages: 'mensajes',
    message: 'mensaje',
    newMessage: 'nuevo',
    select: {
      any: {
        client: 'Cualquier cliente',
        responsible: 'Cualquier usuario',
      },
    },
  },
  project: {
    download: {
      flat: 'Plano',
      hierarchical: 'Jerárquico',
    },
  },
  component: {
    orders: 'Pedidos',
    component: 'Componente',
    status: {
      label: 'Estado del componente',
      any: 'Cualquier estado',
      open: 'Abierto',
      closed: 'Cerrado',
      downloaded: 'Descargado',
    },
    orderId: 'ID Pedido',
    project: 'Proyecto',
    preview: 'Vista previa del componente',
    documents: 'Documentos del componente',
    downloadAll: 'Descargar todo',
    downloads: 'Documentos para descargar',
    uploadPdf: 'Subir Pdf',
    noFiles: 'No hay archivos',
    messages: {
      title: 'Mensajes',
      message: {
        placeholder: 'Escribir un mensaje',
      },
      button: 'Enviar mensaje',
    },
    upload: {
      title: 'Añadir documento',
      name: {
        label: 'Nombre del documento',
        placeholder: 'Escibir nombre del documento',
      },
      button: 'Subir',
    },
    download: {
      deleteConfirm: {
        title: 'Borrar documento',
        message: '¿Está seguro de que desea eliminar el documento?',
        buttonOk: 'Borrar',
        buttonCancel: 'Cancelar',
      },
      deleteToast: {
        title: 'Borrar documento',
        message: 'Documento eliminado con éxito.',
      },
      uploadToast: {
        title: 'Subir documento',
        message: 'Documento subido con éxito.',
      },
    },
    updateToast: {
      title: 'Actualizar componente',
      message: 'El componente se ha actualizado correctamente.',
    },
    messageCreateToast: {
      title: 'Enviar mensaje',
      message: 'Mensaje enviado con éxito.',
    },
    updateOrderToast: {
      title: 'Actualizar pedido',
      message: 'El pedido se ha actualizado correctamente.',
    },
  },
  fileUpload: {
    label: 'Arrastrar y soltar o hacer clic para cargar',
  },
  clients: {
    title: 'Clientes',
    addClient: 'Añadir cliente',
    status: {
      label: 'Estado',
      any: 'Cualquier estado',
      active: 'Activo',
      inactive: 'Inactivo',
    },
    columns: {
      company: 'Compañía',
      email: 'Email',
      lab: 'Nombre del laboratorio',
      contactPerson: 'Persona de contacto',
      status: 'Estado',
    },
    actions: {
      sendInvite: 'Envían invitación',
      delete: 'Borrar',
      blocked: 'Bloquear',
      unBlock: 'Desatascar',
    },
    deleteConfirm: {
      title: 'Eliminar cliente',
      message: '¿Está seguro de que desea eliminar el cliente?',
      buttonOk: 'Borrar',
      buttonCancel: 'Cancelar',
    },
    blockedConfirm: {
      title: 'Bloquear cliente',
      message: '¿Estás seguro de que quieres bloquear al cliente?',
      buttonOk: 'Bloquear',
      buttonCancel: 'Cancelar',
    },
    unblockedConfirm: {
      title: 'Desbloquear cliente',
      message: '¿Estás seguro de que quieres desbloquear al cliente?',
      buttonOk: 'Desatascar',
      buttonCancel: 'Cancelar',
    },
    deleteToast: {
      title: 'Eliminar cliente',
      message: 'Cliente eliminado con éxito.',
    },
    blockToast: {
      title: 'Bloquear cliente',
      message: 'Cliente bloqueado con éxito.',
    },
    unblockToast: {
      title: 'Desbloquear cliente',
      message: 'Cliente desbloqueado con éxito.',
    },
    blockNotAllowedToast: {
      title: 'Desbloquear cliente',
      message:
        'Este cliente está bloqueado por el sistema. Comuníquese con su administrador.',
    },
    client: {
      title: 'Agregar cliente',
      button: 'Añadir cliente',
    },
    activation: {
      title: 'Instrucciones de activación',
      info:
        'Cliente añadido con éxito. Puede copiar y utilizar esta plantilla de mensaje para ponerse en contacto con el cliente recién agregado.',
      message: {
        label: 'Mensaje de invitación',
        placeholder: 'Introducir mensaje de invitación',
      },
      copy: 'Copiar mensaje',
      send: 'Enviar correo electrónico',
      confirm: {
        title: 'Correo de instrucciones',
        message:
          'El correo con instrucciones se ha enviado correctamente al cliente',
        buttonOk: 'Cerca',
      },
      errorConfirm: {
        title: 'Error en las instrucciones de activación',
        buttonOk: 'Ok',
      },
    },
    millingcenterClientAdded: {
      title: 'Cliente Millingcenter agregado',
      message:
        "Cliente centro de fresado '{{millClient}}' se ha añadido al espacio de trabajo.",
      buttonOk: 'Excelente',
    },
  },
  messages: {
    title: 'Mensajes',
    status: {
      any: 'Cualquier mensaje',
      read: 'Leídos',
      unread: 'No leídos',
    },
    noMessages: 'No tienes mensajes',
  },
  settings: {
    title: 'Ajustes',
    tabs: {
      landingPage: 'Landing Page',
      companyDetails: 'Detalles de la empresa',
      users: 'Usuarios',
      licenseKeys: 'Claves de licencia',
    },
    company: {
      logo: {
        title: 'Logo de la compañía',
        label: 'Logo',
      },
      info: {
        title: 'Información de la compañía',
      },
      button: 'Actualziar',
      message: {
        update: 'Actualice los datos de su empresa.',
      },
      error: {
        title: 'Se produjo un error',
        message:
          'Se produjo un error al guardar los detalles de la página de destino.',
      },
    },
    users: {
      add: 'Añadir usuario',
      edit: 'Editar Usuario',
      roles: {
        title: 'Roles',
        all: 'Todos los roles',
        admin: 'Admin',
        user: 'Usuario',
      },
      columns: {
        name: 'Nombre',
        email: 'Email',
        role: 'Rol',
      },
      actions: {
        delete: 'Borrar',
        edit: 'Editar',
      },
      deleteConfirm: {
        title: 'Borrar usuario',
        message: '¿Estás seguro de que deseas eliminar al usuario?',
        buttonOk: 'Borrar',
        buttonCancel: 'Cancelar',
      },
      addToast: {
        title: 'Agregar usuario',
        message: 'Usuario creado con éxito.',
      },
      editToast: {
        title: 'Editar usuario',
        message: 'El usuario se actualiza con éxito.',
      },
      deleteToast: {
        title: 'Borrar usuario',
        message: 'Usuario eliminado exitosamente.',
      },
      error: {
        title: 'Se produjo un error',
        message: 'Ocurrió un error al guardar los detalles de los usuarios.',
      },
    },
    licenseKey: {
      add: 'Agregar clave de licencia',
      columns: {
        licenseKey: 'Clave de licencia',
        placeholder: 'Introduzca la clave de la licencia',
        enableCollection: 'Habilitar la recopilación de datos',
      },
      actions: {
        delete: 'Borrar',
      },
      deleteConfirm: {
        title: 'Eliminar clave de licencia',
        message: '¿Está seguro de que desea eliminar la clave de licencia?',
        buttonOk: 'Borrar',
        buttonCancel: 'Cancelar',
      },
      addToast: {
        title: 'Agregar clave de licencia',
        message: 'Clave de licencia agregada exitosamente.',
      },
      editToast: {
        title: 'Edit license key',
        message:
          'La información de la clave de licencia se actualizó correctamente.',
      },
      deleteToast: {
        title: 'Eliminar clave de licencia',
        message: 'La clave de licencia se eliminó correctamente.',
      },
      error: {
        title: 'Se produjo un error',
        message:
          'Se produjo un error al guardar los detalles de las claves de licencia.',
      },
    },
    landingPage: {
      intro: 'Crea tu landing page.',
      copy: {
        label: 'Copiar URL',
        button: 'Copiar',
      },
      logo: 'Logo',
      header: {
        label: 'Ecabezado',
        placeholder: 'Escribir título de encabezado',
      },
      content: {
        label: 'Contenido',
        placeholder: 'Escriba una descripción',
      },
      update: 'Actualziar',
      preview: {
        btnTxt: 'Vista previa Landing Page',
        closeBtnTxt: 'Cerrar Vista previa',
        signIn: 'Registrarse',
      },
      logoAlt: 'Logotipo de la empresa',
      notify: {
        title: 'Actualizar página de destino',
        message:
          'La página de destino aún no se ha revisado. ¿Quieres hacerlo ahora?',
        okText: 'Proceder',
        cancelText: 'Saltar por ahora',
      },
    },
  },
  imageUpload: {
    button: 'Navegar',
  },
  countries: {
    empty: 'Seleccione un país',
    de: 'Alemania',
    us: 'Estados Unidos',
    es: 'España',
  },
  support: {
    title: 'Soporte',
    tabs: {
      contact: 'Contacto',
      downloadApp: 'Descarga la App',
    },
    contact: {
      info: '¿Cómo podemos ayudar?',
      title: 'Escríbenos',
      topic: {
        label: 'Tema',
        placeholder: '¿Cuál es tu tema?',
      },
      topics: {
        empty: 'Elija un tema',
        topicA: 'Tema A',
        topicB: 'Tema B',
        topicC: 'Tema C',
      },
      message: {
        label: 'Mensaje',
      },
      submit: 'Enviar',
      callUs: 'Llámanos',
      line: 'Líneas de apoyo',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Email de contacto',
      },
    },
    downloadApp: {
      info: 'App de escritorio para tu pc',
      download: 'Descarga',
      toastTitle: 'Descarga la App',
      notFound: 'La aplicación no está disponible',
    },
  },
  account: {
    title: 'Cuenta',
    tabs: {
      company: 'Información de la compañía',
      settings: 'Ajustes',
      profile: 'Perfil',
    },
    company: {
      companyInfo: 'Administre la información de su empresa.',
      logo: 'Logo de la Empresa',
      information: 'Info',
      contact: 'Contacto',
      address: 'Dirección',
      shipping: {
        title: 'Envío',
        address: 'Dirección de envío',
        sameAsAddress: 'La misma que la dirección',
        street: {
          label: 'Calle',
          placeholder: 'Introduzca la calle de envío',
        },
        city: {
          label: 'Ciudad',
          placeholder: 'Ingrese la ciudad de envío',
        },
        zip: {
          label: 'Código postal',
          placeholder: 'Ingrese el código postal de envío',
        },
      },
      button: 'Actualziar',
    },
    settings: {
      info: 'Administre su configuración personal.',
      password: {
        title: 'Contraseña',
        label: 'Nueva contraseña',
        placeholder: 'Escribe nueva contraseña',
      },
      passwordConfirmation: {
        label: 'Confirmar Contraseña',
        placeholder: 'Escriba y confirme la contraseña',
      },
      updatePassword: 'Actualiza contraseña',
      notifications: {
        title: 'Notificaciones',
        notificationOnNewMessageReceived: 'Nuevo mensaje recibido',
        sendEmailOnNewMessageReceived: 'Correo electronico en mensaje nuevo',
        notificationOnNewProjectIsCreated: 'Nuevo pedido creado',
        sendEmailOnNewProjectIsCreated: 'Correo electrónico en el nuevo pedido',
        notificationOnOrderProcessStart: 'Inicio del proceso de pedido',
        notificationOnOrderProcessCompleted: 'Proceso de pedido completado',
      },
      language: {
        title: 'Idioma',
      },
      project: {
        title: 'Proyecto',
        folderStructure: 'Descargar estructura de carpetas',
      },
    },
    profile: {
      info: 'Administre la información de su perfil.',
      button: 'Actualziar',
    },
    updateProfileToast: {
      title: 'Actualización del perfil',
      message: 'Perfil actualizado con éxito.',
    },
    updatePasswordToast: {
      title: 'Actualiza contraseña',
      message: 'Contraseña actualizada exitosamente.',
    },
    updateNotificationToast: {
      title: 'Notificación de actualización',
      message: 'Notificación actualizada correctamente.',
    },
    updateLanguageToast: {
      title: 'Actualizar idioma',
      message: 'Idioma actualizado exitosamente.',
    },
    updateCompanyToast: {
      title: 'Actualizar empresa',
      message: 'Empresa actualizada correctamente.',
    },
    invalidVAT: {
      title: 'Número de IVA no válido',
      message:
        'El número de IVA proporcionado no es correcto, introduzca un número de IVA VÁLIDO.',
    },
  },
  switch: {
    on: 'On',
    off: 'Off',
  },
  auth: {
    registerConfirm: {
      title: 'Confirmation link',
      message:
        'Le hemos enviado un enlace de confirmación por correo. Por favor revisa tu correo y activa tu cuenta.',
      buttonOk: 'De acuerdo',
    },
    confirmationConfirm: {
      title: 'Sign up',
      message:
        '¡Bienvenido a bordo de {{name}}! Su cuenta ahora está activa. Puedes empezar a utilizar nuestra plataforma.',
      buttonOk: 'Genial',
    },
    forgotConfirm: {
      title: 'Olvidé la confirmación de contraseña',
      message:
        'Para restablecer su contraseña por favor revise su correo electrónico.',
    },
    resetConfirm: {
      title: 'Restablecer confirmación de contraseña',
      message: 'Tu contraseña ha sido cambiada exitosamente.',
    },
    accountCreationConfirm: {
      title: 'Regístrese exitosamente',
      error: 'Error de registro',
      message:
        '¡Bienvenido a bordo de {{name}}! Su cuenta ahora está activa. Puedes empezar a utilizar nuestra plataforma.',
      buttonOk: 'Genial',
    },
    millCenterAdded: {
      title: 'Centro de fresado añadido',
      message:
        "Se ha unido al espacio de trabajo de millingcenter '{{millName}}'.",
      buttonOk: 'Genial',
    },
  },
  reset: {
    title: 'Restablecer la contraseña',
    info: 'Por favor ingrese su nueva contraseña',
    button: 'Cambia tu contraseña',
  },
  accountCreation: {
    title: 'Creación de cuenta',
    info: 'Para completar la creación de su cuenta, cree una contraseña.',
    password: {
      label: 'Crear contraseña',
      placeholder: 'Introducir la contraseña',
    },
    button: 'Activate account',
  },
  invitation: {
    title: 'Invitación',
    emailLabel: 'Email',
    message: "Ha sido invitado al espacio de trabajo '{{millName}}'.",
    submitBtnText: 'Crear una nueva cuenta',
    connectToAccount: 'Conectarse a una cuenta existente',
    connectToAccountMsg:
      'Si ya tiene una cuenta de hyperDENT Connect existente, puede agregarle este espacio de trabajo.',
    or: 'O',
    addMillToAccountTxt: 'Agregar fresado a esta cuenta',
    createNewAccount: 'Crear una nueva cuenta',
    sendConfirmationEmail: 'Enviar correo electrónico de confirmación',
    existingAccount: 'Cuenta existente',
    existingAccountMsg:
      'El correo electrónico que ingresó está asociado con una cuenta de hyperDENT Connect existente. Puede agregar un nuevo molino a esa cuenta o crear una nueva cuenta usando una dirección de correo electrónico diferente.',
  },
  landingPage: {
    signIn: 'Registrarse',
  },
  unsavedChanges: {
    message: 'Tienes cambios sin guardar, ¿estás seguro de que quieres salir?',
  },
  contract: {
    title: 'Contrato',
    error: 'Ocurrió un error',
    tabs: {
      contractType: {
        title: 'Tipo de contrato',
        subscribe: 'Suscribir',
        unsubscribe: 'Darse de baja',
        resubscribe: 'Volver a suscribirse',
        addOnConfirmation:
          'Los archivos que conservan una duración de {{addOnDuration}} días (€{{getPromotionalPrice}}/mes) se agregan a su suscripción y se cobrarán en la próxima factura.',
        addOnUnsubscription:
          'El complemento básico con una duración mínima de conservación de archivos se selecciona con su plan.',
        subscriptionSuccess: 'Te has suscrito con éxito a tu plan seleccionado',
        subscriptionFailure:
          'Ocurrió un error al suscribirse a su plan seleccionado. Póngase en contacto con su administrador o vuelva a intentarlo más tarde',
        unsubscriptionSuccess:
          'Se ha dado de baja con éxito de su plan seleccionado',
        unsubscriptionFailure:
          'Ocurrió un error al darse de baja de su plan seleccionado. Póngase en contacto con su administrador o vuelva a intentarlo más tarde',
        resubscriptionSuccess:
          'Has reanudado exitosamente tu plan seleccionado',
        resubscriptionFailure:
          'Se produjo un error al reanudar su plan seleccionado. Comuníquese con su administrador o vuelva a intentarlo más tarde.',
        month: 'Mes',
        cancelsAt: 'La suscripción finaliza en: ',
        free: 'Gratis',
        silver: 'Silver',
        gold: 'Gold',
        basic: 'Básico',
        essential: 'Esencial',
        enterprise: 'Empresa',
        custom: 'Personalizado',
        storage: '{{dataLimit}}Mbs de almacenamiento en la nube.',
        orders: 'Crear hasta {{ordersLimit}} orders',
        clients: 'Invitar hasta {{clients}} clientes',
        supportAvailable: 'El soporte está disponible',
        supportNotAvailable: 'El soporte no está disponible',
        cancel: 'cancelar',
        confirm: 'confirmar',
        upgrade: 'Mejorar',
        cancelConfirmation:
          '¿Estás seguro de que deseas cancelar tu suscripción?',
        resubscriptionConfirmation:
          '¿Estás seguro de que quieres reanudar tu suscripción?',
        subscriptionConfirmation:
          'Su suscripción se reanudará y actualizará. ¿Confirmas?',
        upgradesubscriptionConfirmation:
          '¿Está seguro de que desea actualizar/cambiar su suscripción?',
        subscriptionConsent:
          'Gracias por elegir la suscripción a hyperDENT Connect. Proporcione su consentimiento para continuar.',
        payment: {
          success:
            'Su pago ha sido recibido con éxito. Será redirigido en breve.',
          failure:
            'El proceso de pago no se realizó correctamente debido a una interrupción manual o información de tarjeta no válida. Será redirigido en breve.',
        },
      },
      usageDetail: {
        title: 'Detalles de uso',
        resourceName: 'Nombre del recurso',
        used: 'Usado',
        limit: 'Límite',
        usagePercentage: 'Porcentaje utilizado',
        errorMessage: 'Se produjo un error al cargar los detalles de uso.',
      },
      billing: {
        title: 'Facturación',
        custEmail: 'Correo electrónico del cliente',
        custComp: 'Nombre de empresa',
        fromPeriod: 'Periodo desde',
        toPeriod: 'Período Hasta',
        amount: 'Suma',
        errorMessage:
          'Se produjo un error al cargar los detalles de facturación.',
        manage: 'Administrar facturación',
      },
      invoices: {
        title: 'Facturas',
        id: 'Id Factura',
        itemDesc: 'Descripción',
        amountDue: 'Importe adeudado',
        download: 'Descarga',
        isPaid: 'Está pagado',
        paid: 'Pagado',
        due: 'Pendiente',
        errorMessage: 'Se produjo un error al cargar la lista de facturas.',
        totalAmountDue: 'Monto total adeudado',
        dateDue: 'Fecha de vencimiento',
        upcoming: 'Próxima factura',
        buttonOk: 'OK',
      },
    },
    addons: {
      title: 'Complementos',
      keepFiles: 'Duración de mantenimiento de archivos',
      days: 'días',
      month: 'mes',
    },
  },
  errorBoundary: {
    errorMessage: 'Huy! Algo salió mal',
    errorFix: 'Error técnico, estamos trabajando para solucionarlo',
  },
  notifications: {
    label: 'Notificaciones',
    new: 'Nueva Notificación',
    none: 'No tienes ninguna notificación.',
    markAsUnread: 'Marcar como no leído',
    filter: {
      all: 'Todo',
      unread: 'No leído',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Error',
        message: 'Algo salió mal al generar el informe en PDF',
      },
      success: {
        title: 'Éxito',
        message: 'El informe PDF se genera correctamente.',
      },
    },
    dateFormat: 'Formato de fecha del informe',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    dongleId: 'Dongle Id',
    isNetwork: 'Is Network',
    isCommercial: 'Is Commercial',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
  dealerManagement: {
    dealerHome: {
      heading: ' Welcome to the dealers dashboard ',
      navParagraph:
        'You can go to any of the following dashboard views by navigating from the sidebar or from the below mentioned links',
      navMenu: {
        dealerInformation: 'Dealer Information',
        dealerTickets: 'Dealer Tickets',
        dealerSettings: 'Dealer Settings',
      },
    },
    heading: 'Dealers Management',
    invitation: 'Invite as User',
    reInvite: 'Reinvite User',
    newUser: 'New Dealer',
    dealerId: 'Dealer Id',
    name: 'Name',
    configurations: {
      subDealerAllowed: 'Can Create Sub Dealers',
      ownConfigAllowed: 'Can Set Own Configurations',
      parentDealerId: 'Parent Dealer Id',
      parentDealerName: 'Parent Dealer Name',
      heading: 'Configurations',
      baseURL: 'Components Base URL',
      configType: 'Config Type',
      json: 'Configurations Json',
      file: 'Configurations File',
      url: 'Configurations Url',
    },
    contact: {
      dealerContact: 'Dealer Contact',
      supportContact: 'Support Contact',
      salesContact: 'Sales Contact',
      address1: 'Address 1',
      address2: 'Address 2',
      address3: 'Address 3',
      zipCode: 'Zip Code',
      city: 'City',
      district: 'District',
      state: 'State',
      country: 'Country',
      phone: 'Phone',
      email: 'Email',
      webUrl: 'Web Url',
      showInfoToOutOfMaintenanceUsers: 'Show Info To Out Of Maintenance Users',
    },
    configOptions: {
      none: 'None',
      fixedJson: 'Fixed Json',
      fixedFile: 'Fixed File',
      dynamicUrl: 'Dynamic Url',
    },
    buttonSave: 'Save',
    user: {
      heading: 'User Management',
      newUser: 'New User',
      dealer: 'Dealer',
      manager: 'Manager',
      columns: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        role: 'Role',
        actions: 'Actions',
      },
    },
    updateConfirm: {
      title: 'Update E-Mail',
      message:
        'User will need to use this email to log in later. Please confirm?',
      messageSelf:
        'You are updating your email. You will be logged out immediately and can log in later with the updated email after confirmation. Do you want to proceed?',
      buttonOk: 'Update',
      buttonCancel: 'Cancel',
    },
    requiredDealerId: 'Dealer Id is required',
    requiredName: 'Dealer Name is required',
    requiredConfigType: 'Configuration Type is required',
    invalidJson: 'Invalid JSON',
    requiredParentEmail: 'Parent Dealer Id or Email is required',
    emailNotValid: 'Email is not valid',
    tickets: {
      title: 'Dealer Tickets',
      ticketsFound: '{{totalSize}} ticket(s) found',
      dataColumns: {
        ticketId: 'Ticket Id',
        activationStatus: 'Activation Status',
        productText: 'Product Text',
        expiryDate: 'Expiry Date',
        licenseQuantity: 'Quantity',
        maintenanceExpiry: 'Maintenance Expiry',
        parentDealerId: 'Parent Dealer Id',
        dealerId: 'Dealer Id',
        isUpdateAvailable: 'Update Available',
        minimumExpiryDate: 'Minimum Expiry Date',
      },
      pageSize: 'Page Size',
      noActiveTicketsFound:
        'No active product detail found against ticketId "{{ticketId}}"',
      dealersList: 'Dealers List',
    },
    settings: {
      title: 'Settings',
      pageSize: 'Page Size',
      language: 'Language',
    },
  },
  hdUsageInfo: {
    usageInfo: 'hyperDENT Usage Info',
    runningMachines: 'Running Machines',
    concurrentUsers: 'Concurrent Users',
    lastUpdateReceivedAt: 'Last Update Received At',
    licenseKey: 'License Key',
    allowedUsers: 'Allowed Network Users',
    mulipleLogins: 'Multiple Logins',
    firstPing: 'First Ping Received At',
    lastPing: 'Recent Ping Received At',
    concurrentlyUsedCount: 'Concurrently Used Count',
  },
  unknownError: 'An unknown error occured',
  dealerConfig: {
    test: 'Dealer configuration test result',
    invalidTicketFormat:
      'Invalid ticket format. Expected format: {{HD_TICKET_FORMAT_STR}}',
    response: 'Response will be shown here',
    wrapResponse: 'Wrap Response Text',
    initialCall: 'Initial Call',
  },
};
