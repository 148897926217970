import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_TOKEN } from 'constants/constants';
import { IUserObject } from 'pages/Manager/UserManagement/Models/users';

const token = localStorage.getItem(API_TOKEN);

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  }),
  tagTypes: ['Users', 'OneUser'],
  endpoints: (builder) => ({
    getUserList: builder.query<[], void>({
      query: () => {
        return {
          url: `/manager/users`,
          method: 'Get',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ['Users'],
    }),
    addNewUser: builder.mutation<[], IUserObject>({
      query: (data) => {
        return {
          url: '/users/add',
          method: 'Post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        };
      },
      invalidatesTags: ['Users', 'OneUser'],
    }),
    updateNewUser: builder.mutation<[], IUserObject>({
      query: (data) => {
        return {
          url: `/users/update/${data.id}`,
          method: 'Put',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
        };
      },
      invalidatesTags: ['Users', 'OneUser'],
    }),
  }),
});

export const {
  useGetUserListQuery,
  useAddNewUserMutation,
  useUpdateNewUserMutation,
} = userApi;
