import React from 'react';
import {} from 'react-router-dom';
import { ToastContainer } from 'components/Toast/ToastContainer';
import { DealerHeader } from './DealerHeader';
import { DealerSidebar } from './DealerSidebar';

interface IManagerProps {
  children: React.ReactNode;
}

const DealerLayout: React.FC<IManagerProps> = ({ children }: IManagerProps) => {
  return (
    <>
      <header className="header">
        <DealerHeader />
      </header>
      <main>
        <div className="d-flex">
          <div className="sidebar-width">
            <DealerSidebar />
          </div>

          <div className="manager-main">{children}</div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default DealerLayout;
