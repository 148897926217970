import React from 'react';
import { NavLink } from 'react-router-dom';
import { ClientIcon, ContractIcon, SettingsIcon } from 'icons';
import {
  DEALER_INFORMATION_ROUTE,
  DEALER_SETTINGS_ROUTE,
  DEALER_TICKETS_ROUTE,
} from 'constants/routes';

export const DealerSidebar: React.FC = () => {
  return (
    <aside className="sidebar d-md-flex">
      <NavLink to={DEALER_INFORMATION_ROUTE} className="sidebar-link">
        <ClientIcon />
      </NavLink>
      <NavLink to={DEALER_TICKETS_ROUTE} className="sidebar-link">
        <ContractIcon />
      </NavLink>
      <NavLink to={DEALER_SETTINGS_ROUTE} className="sidebar-link">
        <SettingsIcon />
      </NavLink>
    </aside>
  );
};
