import React, { useEffect } from 'react';
import { Select, Spinner2 } from 'components';
import { useAppSelector } from 'store/hooks';
import {
  IDealerListView,
  useFetchDealerTicketsMutation,
  useGetSubDealersByBranchDealerUserQuery,
} from 'thunks/RTKQuery/dealer';
import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { DealerTicketsComponent, ISortBy } from './DealerTicketsComponent';
import { getCurrentPageSizeOption } from '../Settings';

const defaultSortBy: ISortBy = { dataField: 'parentDealerId', order: 'desc' };
interface IDealerOption {
  label: string;
  value: string;
}

export const DealerTicketsContainer = () => {
  const { dealer } = useAppSelector((state) => state.auth.data);
  const [page, setPage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState(defaultSortBy);
  const [dealerOptions, setDealerOptions] = React.useState<IDealerOption[]>([]);

  // this is the root dealer option who is logged-in
  const defaultDealerOption = { label: 'All', value: 'All' };

  const [selectedDealer, setSelectedDealer] = React.useState<IDealerOption>(
    defaultDealerOption
  );

  const { t } = useTranslation();
  const sizePerPage = getCurrentPageSizeOption();

  const { data: dealerList } = useGetSubDealersByBranchDealerUserQuery();

  const [
    fetchDealerTickets,
    { data, isLoading, error },
  ] = useFetchDealerTicketsMutation();

  useEffect(() => {
    if (!Array.isArray(dealerList) || !dealerList.length) return;

    const options: IDealerOption[] = [defaultDealerOption];
    if (dealer) {
      options.push({
        label: dealer.dealerId,
        value: dealer.dealerId,
      });
    }
    dealerList.forEach((subDealer: IDealerListView) => {
      options.push({
        label: subDealer.dealerId,
        value: subDealer.dealerId,
      });
    });
    setDealerOptions(options);
  }, [dealerList]);

  const fetchData = () => {
    if (dealer?.dealerId && selectedDealer?.value && !isLoading) {
      try {
        fetchDealerTickets({
          dealerId: selectedDealer.value,
          sortByColumn: sortBy.dataField,
          sortOrder: sortBy.order,
          page,
          sizePerPage: +sizePerPage.value,
        });
      } catch (err) {
        console.error('Error in fetching dealer tickets', err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [dealer?.dealerId, fetchDealerTickets, page]);

  useEffect(() => {
    // if page is already 1 and any of the dependency is changed, then re-fetch data
    if (page === 1) {
      fetchData();
      return;
    }

    // else, set page to 1 and fetch data hook of change in page will be called
    setPage(1);
  }, [sortBy, selectedDealer]);

  const tickets = data?.tickets ?? [];
  const totalSize = data?.count ?? 0;

  return (
    <DealerLayout>
      <Row style={{ alignItems: 'baseline' }}>
        <Col lg={4}>
          <h1 className="ml-2 mb-4 mt-3">
            {t('dealerManagement.tickets.title')}
          </h1>
        </Col>
        <Col lg={5}>
          {!isLoading && (
            <h6 style={{ color: 'cornflowerblue' }}>
              {t('dealerManagement.tickets.ticketsFound', { totalSize })}
            </h6>
          )}
        </Col>
        <Col lg={1}>
          <h6>{t('dealerManagement.tickets.dealersList')}</h6>
        </Col>
        <Col lg={2}>
          <Select
            options={dealerOptions}
            defaultValue={defaultDealerOption}
            onChange={setSelectedDealer}
          />
        </Col>
      </Row>

      <hr />
      <Row className="mt-3">
        <Spinner2 show={isLoading} />
        {error && <div className="alert alert-danger">{error}</div>}

        {tickets && tickets.length > 0 && (
          <DealerTicketsComponent
            tickets={tickets}
            count={totalSize}
            setPage={setPage}
            setSortBy={setSortBy}
            sortBy={sortBy}
            page={page}
            sizePerPage={+sizePerPage.value}
          />
        )}
      </Row>
    </DealerLayout>
  );
};
