import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authSlice, toastSlice } from 'slices';
import { InviteUsersContainer } from 'components/Modal/InviteUsers/InviteUsersContainer';
import { useTranslation } from 'react-i18next';
import { DealersDetailViewContainer } from 'pages/Shared/Dealer/DealerDetailView/DealersDetailViewContainer';
import { IDealerItem } from 'pages/Shared/Dealer/Models/dealers';
import { useDealerRole, useManagerRole } from 'hooks/role';
import { ManagerDealerListViewContainer } from 'pages/Manager/DealersManagement/DealersListView';
import ManagerLayout from 'components/Layout/Manager/ManagerLayout';
import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { RootDealerListViewContainer } from '../../Dealer/RootDealerListViewContainer';

export const DealersView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedDealer, setSelectedDealer] = useState<IDealerItem>();
  const [dealerListData, setDealerListData] = React.useState<IDealerItem[]>([]);
  const { data: authData } = useAppSelector((state) => state.auth);
  const rootDealer = authData?.dealer;
  const [errorString, setErrorString] = useState('');
  const [showList, setShowList] = useState<boolean>(true);
  const { t } = useTranslation();

  const isDealer = useDealerRole();
  const isManager = useManagerRole();
  const createDealer = () => {
    setSelectedDealer(undefined);
  };

  const inviteUser = () => {
    dispatch(authSlice.actions.showInviteUserModel());
  };

  const appendListViewData = (data: IDealerItem) => {
    if (data) {
      const existingItemIndex = dealerListData.findIndex(
        (x) => x.id === data.id
      );
      if (existingItemIndex !== -1) {
        const updateData = dealerListData.filter((x) => x.id !== data.id);
        updateData.splice(existingItemIndex, 0, data);

        setDealerListData(updateData);
        return;
      }

      if ([data, ...dealerListData].length > 1) {
        setShowList(true);
      }
      setDealerListData([data, ...dealerListData]);
    }
  };

  useEffect(() => {
    if (errorString !== '') {
      dispatch(
        toastSlice.actions.show({
          title: t('contract.error'),
          message: errorString,
        })
      );
    }
    setErrorString('');
  }, [errorString]);
  const Layout = isManager ? ManagerLayout : DealerLayout;
  const canCreateDealer =
    isManager || (rootDealer && rootDealer?.permissions?.subDealerAllowed);

  return (
    <Layout>
      <h1 className="ml-2 mb-4 mt-3">{t('dealerManagement.heading')}</h1>
      <hr />
      <div className="d-flex w-100 justify-content-center page-contract-type mb-3">
        <Col md={showList ? 4 : 2} />
        <Col md={2}>
          <Button
            onClick={inviteUser}
            className={
              !showList
                ? 'btn-outline-primary invite-button-hide-list'
                : 'btn-outline-primary invite-button'
            }
          >
            {selectedDealer?.userId
              ? t('dealerManagement.reInvite')
              : t('dealerManagement.invitation')}
          </Button>
        </Col>
        <Col md={4} />

        <Col md={2}>
          {canCreateDealer && (
            <Button
              onClick={createDealer}
              className={
                !showList
                  ? 'btn-outline-primary create-button-hide-list'
                  : 'btn-outline-primary create-button'
              }
            >
              {t('dealerManagement.newUser')}
            </Button>
          )}
        </Col>
        <Col md={showList ? 0 : 2} />
      </div>
      <div className="d-flex w-100 justify-content-center page-contract-type">
        <Col md={4} className={showList ? 'mr-20' : 'd-none'}>
          {isDealer && (
            <RootDealerListViewContainer
              setErrorString={setErrorString}
              setSelectedDealer={setSelectedDealer}
              selectedDealer={selectedDealer}
              dealerListData={dealerListData}
              setDealerListData={setDealerListData}
              rootDealer={rootDealer}
              setShowList={setShowList}
            />
          )}
          {isManager && (
            <ManagerDealerListViewContainer
              setErrorString={setErrorString}
              setSelectedDealer={setSelectedDealer}
              selectedDealer={selectedDealer}
              dealerListData={dealerListData}
              setDealerListData={setDealerListData}
            />
          )}
        </Col>
        {!showList && <Col md={2} />}
        <Col md={8}>
          <DealersDetailViewContainer
            setErrorString={setErrorString}
            selectedDealer={selectedDealer}
            setSelectedDealer={setSelectedDealer}
            appendListViewData={appendListViewData}
            rootDealer={rootDealer}
          />
        </Col>
        {!showList && <Col md={2} />}
        <InviteUsersContainer
          setErrorString={setErrorString}
          selectedDealer={selectedDealer}
          setSelectedDealer={setSelectedDealer}
          appendListViewData={appendListViewData}
        />
      </div>
    </Layout>
  );
};
