import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getLocalStorageItem, setLocalStorage } from 'utils/localStorage';
import { APP_LANGUAGE, DEALER_TICKETS_PAGE_SIZE } from 'constants/constants';
import { IOption, Select } from 'components/Select';
import DealerLayout from 'components/Layout/Dealer/DealerLayout';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_LANGUAGE_OPTION,
  DEALER_TICKETS_DEFAULT_PAGE_SIZE,
  LANGUAGE_OPTIONS,
  PAGE_OPTIONS,
} from './constants';

const pageSize = getLocalStorageItem(DEALER_TICKETS_PAGE_SIZE);
if (!pageSize) {
  setLocalStorage(
    DEALER_TICKETS_PAGE_SIZE,
    `${DEALER_TICKETS_DEFAULT_PAGE_SIZE}`
  );
}

export const getDealerPageSize = () => {
  return (
    getLocalStorageItem(DEALER_TICKETS_PAGE_SIZE) ||
    DEALER_TICKETS_DEFAULT_PAGE_SIZE
  );
};

const getPageOption = (v: number) => ({ label: `${v}`, value: `${v}` });

export const getCurrentPageSizeOption = () => {
  return getPageOption(+getDealerPageSize());
};

export const DealerSettings: React.FC = () => {
  const { t } = useTranslation();
  const [selectedPageSize] = useState(getDealerPageSize());

  const getPageOptions = () => {
    return PAGE_OPTIONS.map(getPageOption);
  };

  const handlePageChange = (option: IOption) => {
    setLocalStorage(DEALER_TICKETS_PAGE_SIZE, option.value);
  };

  const handleLanguageChange = (option: IOption) => {
    setLocalStorage(APP_LANGUAGE, option.value);
  };

  const getDefaultValue = () => {
    const defaultValue = PAGE_OPTIONS.find(
      (p: number) => p === +selectedPageSize
    );
    return { label: `${defaultValue}`, value: `${defaultValue}` };
  };

  return (
    <DealerLayout>
      <h1>{t('dealerManagement.settings.title')}</h1>
      <hr />
      <Row className="mt-10">
        <Col md={4}>
          <Row className="align-items-center">
            {' '}
            <Col md={3}>
              <h6>{t('dealerManagement.settings.pageSize')}</h6>
            </Col>
            <Col md={9}>
              <Select
                options={getPageOptions()}
                onChange={handlePageChange}
                defaultValue={getDefaultValue()}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="align-items-center">
            <Col md={3}>
              <h6>{t('dealerManagement.settings.language')}</h6>
            </Col>
            <Col md={9}>
              <Select
                options={LANGUAGE_OPTIONS}
                onChange={handleLanguageChange}
                defaultValue={DEFAULT_LANGUAGE_OPTION}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} />
      </Row>
    </DealerLayout>
  );
};
