import React from 'react';
import { useAppSelector } from 'store/hooks';
import { LicenseDetailComponent } from './licenseDetailComponent';

export const LicenseInfoDetails: React.FC = () => {
  const { licenseDetails, nonExpandables } = useAppSelector(
    (state) => state.licenseContent.data
  );

  return (
    <>
      <LicenseDetailComponent
        data={licenseDetails}
        nonExpandables={nonExpandables}
      />
    </>
  );
};
