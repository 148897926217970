import { ColumnDescription } from 'react-bootstrap-table-next';
import { IManagerMillUser } from 'interfaces/Manager/user';
import i18n from 'translations';

export const managerColumns: ColumnDescription<any, IManagerMillUser>[] = [
  {
    dataField: 'id',
    text: 'Id',
    hidden: true,
  },
  {
    dataField: 'dealerId',
    text: i18n.t('dealerManagement.dealerId'),
    style: { width: '22%' },
    sort: true,
  },
  {
    dataField: 'name',
    text: i18n.t('dealerManagement.name'),
    style: { width: '45%' },
    sort: true,
  },
  {
    dataField: 'email',
    text: i18n.t('email.label'),
    hidden: true,
  },
  {
    dataField: 'parentDealerId',
    text: i18n.t('dealerManagement.configurations.parentDealerId'),
    style: { width: '33%' },
    sort: true,
  },
];
