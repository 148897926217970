import React from 'react';
import { IDealerTicket } from 'thunks/RTKQuery/dealer';
import { Table } from 'components/Table';
import { TableChangeType, SortOrder } from 'react-bootstrap-table-next';
import { columns } from './columns';
import { expandDTRow } from './expandDTRow';

export interface ISortBy {
  order: SortOrder;
  dataField: string;
}

interface IDealerTicketsResponseComponent {
  tickets: IDealerTicket[];
  count: number;
  page: number;
  sizePerPage: number;
  setPage: (page: number) => void;
  setSortBy: (sortBy: ISortBy) => void;
  sortBy: ISortBy;
}

export const DealerTicketsComponent: React.FC<IDealerTicketsResponseComponent> = ({
  tickets,
  count,
  setPage,
  setSortBy,
  page,
  sizePerPage,
  sortBy,
}: IDealerTicketsResponseComponent) => {
  return (
    <Table
      data={tickets}
      keyField="ticketId"
      columns={columns}
      page={page}
      sizePerPage={sizePerPage}
      totalSize={count}
      defaultSorted={[sortBy]}
      onTableChange={(type: TableChangeType, state) => {
        if (type === 'pagination') setPage(state.page);
        if (type === 'sort') {
          if (
            state.sortField !== sortBy.dataField ||
            state.sortOrder !== sortBy.order
          ) {
            setSortBy({ dataField: state.sortField, order: state.sortOrder });
          }
        }
      }}
      expandRow={expandDTRow()}
    />
  );
};

DealerTicketsComponent.defaultProps = {};
